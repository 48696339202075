export const OFFLINE: string = "OFFLINE";
export const IDLE: string = "IDLE";
export const BUSY: string = "BUSY";

export enum PlatformAccessibility {
	AVAILABLE_FOR_SCHEDULING = "AVAILABLE_FOR_SCHEDULING",
	PUBLIC = "PUBLIC",
	PRIVATE = "PRIVATE",
	SHARED = "SHARED",
	NOT_AVAILABLE = "NOT_AVAILABLE"
}

export enum PlatformNodeDeployment {
	UNMANAGED = "UNMANAGED",
	MANAGED_ON_HOLD = "MANAGED_ON_HOLD",
	MANAGED_DEPLOYED = "MANAGED_DEPLOYED"
}

export enum PlatformState {
	BUSY = "BUSY",
	IDLE = "IDLE",
	UNKNOWN = "UNKNOWN",
	UNREGISTERED = "UNREGISTERED"
}

export interface PlatformDto {
	uuid: string;
	topLevelType: string;
	friendlyName?: string;
	operatorUserId: string;
	operatorDisplayName?: string;
	authorizationScope: string;
	unregistered: boolean;
	accessibility: PlatformAccessibility;
	nodeDeployment: PlatformNodeDeployment;
	properties?: PlatformProperty[];
	state: PlatformState;
	connected: boolean;
}

export interface SimpleStringProperty {
	type: "propertyAndReqType.simpleString" | 'string';
	value: string;
}

export interface ProvidesFunctionProperty {
	type: "propertyType.providesFunctions";
	values: string[];
}

export interface MultivalueProperty {
	type: "propertyType.multivalue" | 'list';
	values: string[];
}

export type PlatformProperty = { propertyId: string } & (SimpleStringProperty | ProvidesFunctionProperty | MultivalueProperty);