import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, Box, Button, Divider, Grid, TextField, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Context } from '../context/ContextStore';
import useApiClient from '../hooks/useApiClient';
import PagedResult from '../model/services/PagedResult';
import CeeProjectDto from '../model/services/project-service/CeeProjectDto';

const ProjectSelector: React.FunctionComponent = () => {
	const DEFAULT_PAGE = 0;
	const DEFAULT_ROWS_PER_PAGE = 25;

	const [context,] = useContext(Context);

	const [page, setPage] = useState(DEFAULT_PAGE);
	const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

	const [selectedProject, setSelectedProject] = useState<CeeProjectDto>();

	const navigate = useNavigate();
	const location = useLocation();

	const theme = useTheme();

	const apiClient = useApiClient();

	const { ceeProjectUuid } = useParams();

	const projectsQuery = useQuery(["cee-projects"], () => apiClient.get(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects`, { searchParams: { page: page, size: rowsPerPage } })
		.json<PagedResult<CeeProjectDto>>()
	);

	const allProjectsCount = projectsQuery.data?.count;
	const projects = projectsQuery.data?.data;

	useEffect(() => selectedProject && localStorage.setItem("selectedProject", selectedProject.uuid ?? ''), [selectedProject]);

	useEffect(() => {
		if (!projects || allProjectsCount === 0) {
			setSelectedProject(undefined);
			return;
		}

		// If no project has been specified use the first one
		if (!ceeProjectUuid) {
			if (!location.pathname.endsWith("/new")) {
				let projectToShow: CeeProjectDto | undefined = undefined;

				let savedProjectUUID = localStorage.getItem("selectedProject");
				if (savedProjectUUID) {
					projectToShow = projects.filter(p => p.uuid === savedProjectUUID)[0];
				}

				if (!projectToShow) {
					projectToShow = projects?.at(0);
				}

				setSelectedProject(projectToShow);
				navigate(projectToShow?.uuid ?? "");
			} else {
				setSelectedProject(undefined);
			}
		}

		// If both project and ceeProjectUuid are present, try to find the selected project
		let selectedProjects = projects?.filter(p => p.uuid === ceeProjectUuid);
		if (selectedProjects?.length === 1) {
			// Since we filter for the UUID this selector is safe
			setSelectedProject(selectedProjects.at(0));
		} else {
			setSelectedProject(undefined);
		}
	}, [ceeProjectUuid, allProjectsCount, projects, navigate, location.pathname]);

	return (
		<Grid container direction="column">
			<Grid container direction="row" wrap="nowrap" justifyContent="flex-start" sx={{ px: 2, backgroundColor: theme.palette.grey[100] }}>
				<Grid item width="30%">
					<Box paddingTop="1em" paddingBottom="1em">
						<Autocomplete
							id="project-combo"
							value={selectedProject ?? new CeeProjectDto()}
							options={projects ?? [new CeeProjectDto()]}
							getOptionLabel={option => option.name ?? "<No Name Specified>"}
							disablePortal
							disableClearable
							renderInput={(params) => <TextField {...params} label="Selected Project" />}
							onChange={(_, value) => {
								if (!value || !value.uuid) {
									return;
								}

								navigate(value.uuid);
								setPage(DEFAULT_PAGE);
								setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
							}}
							isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
							size="small"
						/>
					</Box>
				</Grid>
				<Grid item alignSelf="center" sx={{ mx: "1em" }}>
					<Button
						variant="contained"
						startIcon={<AddIcon />}
						onClick={() => navigate("new")}
					>
						Create Project
					</Button>
				</Grid>
			</Grid>
			<Divider />
			<Grid item flexGrow={1} sx={{ px: 2, pt: 2 }}>
				<Outlet />
			</Grid>
		</Grid>
	)
}

export default ProjectSelector;

