import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, IconButtonProps } from "@mui/material";
import React, { useState } from "react";

interface ConfirmationDialogIconButtonProps extends IconButtonProps {
	title?: string;
	text?: string;
	onConfirmation: () => void;
}

const ConfirmationDialogIconButton = React.forwardRef<HTMLElement, ConfirmationDialogIconButtonProps>((props, ref) => {
	let { title, text, onConfirmation, ...otherProps } = props;

	const [dialogOpen, setDialogOpen] = useState(false);

	function handleClose(): void {
		setDialogOpen(false);
	}

	return (
		<Box>
			<IconButton {...otherProps} onClick={() => setDialogOpen(true)}>
				{props.children}
			</IconButton >
			<Dialog open={dialogOpen} onClose={handleClose}>
				<DialogTitle>
					{title || "Confirmation"}
				</DialogTitle>
				<DialogContent>
					{text || "Are you sure you want to do this?"}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => handleClose()}
					>
						Close
					</Button>
					<Button
						variant="contained"
						onClick={() => {
							onConfirmation();
							handleClose();
						}}
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
})

export default ConfirmationDialogIconButton;