import { Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import RepositoryMappingTable from "./RepositoryMappingTable";
import RepositoryTable from "./RepositoryTable";

const SingleProjectRepositories: React.FunctionComponent = () => {
	const { ceeProjectUuid } = useParams();

	return (
		<Grid container direction="column">
			<Grid item>
				<RepositoryTable ceeProjectUuid={ceeProjectUuid} />
			</Grid>
			<Grid item>
				<RepositoryMappingTable projectUuid={ceeProjectUuid} />
			</Grid>
		</Grid >
	)
}

export default SingleProjectRepositories;