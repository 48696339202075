import colorLib from '@kurkle/color';

namespace Utils {
	/* Taken from https://emailregex.com */
	export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	export function transparentize(value: string, opacity: number) {
		var alpha = opacity === undefined ? 0.5 : 1 - opacity;
		return colorLib(value).alpha(alpha).rgbString();
	}

	export const CHART_COLORS = [
		'#4dc9f6',
		'#f67019',
		'#537bc4',
		'#acc236',
		'#166a8f',
		'#00a950',
		'#58595b',
		'#8549ba',
		'#f53794',
	];

	export function chartColor(index: number) {
		return CHART_COLORS[index % CHART_COLORS.length];
	}

	const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	export function niceBytes(n: number) {

		let l = 0;

		while (n >= 1024 && ++l) {
			n = n / 1024;
		}

		return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
	}
}

export default Utils;