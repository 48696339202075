import React from 'react';
import { Box, Typography } from '@mui/material';

const PageNotFound: React.FunctionComponent = () => {
	return (
		<Box width='100%' height='100%' display='flex' justifyContent='center'>
			<Box display='flex' flexDirection='column' justifyContent='center'>
				<Typography variant='h6'>
					Sorry. The page you're looking for doesn't exist.
				</Typography>
			</Box>
		</Box>
	)
}

export default PageNotFound;