import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Chip, Grid, Paper, Skeleton, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import clsx from "clsx";
import React, { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Context } from '../../context/ContextStore';
import useApiClient from '../../hooks/useApiClient';
import { PlatformAccessibility, PlatformDto, PlatformProperty, PlatformState } from '../../model/cee/PlatformDto';
import PagedResult from '../../model/services/PagedResult';

const PREFIX = 'Platforms';

const classes = {
	container: `${PREFIX}-container`,
	unregistered: `${PREFIX}-unregistered`,
	circle: `${PREFIX}-circle`,
	circleDisconnected: `${PREFIX}-circle-disconnected`,
	ringring: `${PREFIX}-ringring`,
}

const StyledBox = styled(Box)(({ theme }) => ({
	padding: '1em',
	display: 'flex',
	flexGrow: 1,
	[`& .${PREFIX}-${PlatformState.IDLE}`]: {
		[`& .${classes.circle}`]: { backgroundColor: "#62bd19" },
		[`& .${classes.ringring}`]: { borderColor: "#62bd19" }
	},
	[`& .${PREFIX}-${PlatformState.UNKNOWN}`]: {
		[`& .${classes.circle}`]: { backgroundColor: theme.palette.error.main },
		[`& .${classes.ringring}`]: { borderColor: theme.palette.error.main }
	},
	[`& .${PREFIX}-${PlatformState.BUSY}`]: {
		[`& .${classes.circle}`]: { backgroundColor: "orange" },
		[`& .${classes.ringring}`]: { borderColor: "orange" }
	},
	[`& .${PREFIX}-${PlatformState.UNREGISTERED}`]: {
		[`& .${classes.circle}`]: { backgroundColor: "lightgray" },
		[`& .${classes.ringring}`]: { borderColor: "lightgray" }
	},
	[`& .${classes.unregistered} td, & .${classes.unregistered} td > *`]: {
		color: 'lightgray!important',
		fontWeight: 'normal!important'
	},
	[`& .${classes.circle}`]: {
		width: "15px",
		height: "15px",
		transform: "translateX(-50%) translateY(-50%)",
		borderRadius: "50%",
		position: "absolute",
		top: "50%",
		left: "50%"
	},
	[`& .${classes.circleDisconnected}`]: {
		clipPath: 'polygon(100% 0, 65% 0, 55% 30%, 81% 30%, 49% 89%, 51% 48%, 24% 48%, 39% 0, 0 0, 0 100%, 100% 100%)'
	},
	"@keyframes pulsate": {
		"0%": { transform: "translateX(-50%) translateY(-50%) scale(0.1, 0.1)", opacity: 0.0 },
		"10%": { opacity: 1.0 },
		"30%": { transform: "translateX(-50%) translateY(-50%) scale(1.2, 1.2)", opacity: 0.0 }
	},
	[`& .${classes.ringring}`]: {
		border: "3px solid",
		borderRadius: "50%",
		height: "25px",
		width: "25px",
		position: "absolute",
		transform: "translateX(-50%) translateY(-50%)",
		transformOrigin: "center",
		top: "50%",
		left: "50%",
		animation: "pulsate 2.5s infinite ease-in-out",
		opacity: 0.0,
	}
}));

const PlatformAvailabilityLabels: Map<PlatformAccessibility, string> = new Map([
	[PlatformAccessibility.AVAILABLE_FOR_SCHEDULING, "Available"],
	[PlatformAccessibility.NOT_AVAILABLE, "Not Available"],
	[PlatformAccessibility.PRIVATE, "For Private Use"]
]);

const knownPropertyNames: { [key: string]: string } = {
	"property.providesFunctions": "Provides Functions",
	"platformType": "Platform Type",
	"availablePlatformTypes": "Available Platform Types",
	"platformVersion": "Platform Version",
	"platformName": "Platform Name"
};

const PlatformTable: React.FunctionComponent = () => {

	const [context,] = useContext(Context);
	const apiClient = useApiClient();

	const queryClient = useQueryClient();

	const platformsQuery = useQuery(['platforms'], () =>
		apiClient.get(`${context.config?.CEE_CORE_URL}/api/v1/platforms`)
			.json<PagedResult<PlatformDto>>()
			.then(result => result.data
				.filter(e => e.topLevelType)
				.sort((a, b) => {
					const unregisteredSort = (a.unregistered ? 1 : 0) - (b.unregistered ? 1 : 0);
					const connectedSort = (b.connected ? 1 : 0) - (a.connected ? 1 : 0);
					return unregisteredSort !== 0 ? unregisteredSort :
						connectedSort !== 0 ? connectedSort :
							a.uuid.localeCompare(b.uuid);
				})),
		{
			keepPreviousData: true,
			refetchInterval: 2000
		}
	);

	const platforms = platformsQuery.data;

	const removePlatformsWithFilter = useMutation(() =>
		apiClient.delete(`${context.config?.CEE_CORE_URL}/api/v1/platforms`),
		{
			onSuccess: () => queryClient.invalidateQueries('platforms')
		}
	);

	return (
		<StyledBox>
			<Grid container direction="column">
				<Grid item paddingBottom="1em">
					<Grid container direction="row" justifyContent="flex-end">
						<Grid item>
							<Button
								variant="outlined"
								color="error"
								onClick={() => removePlatformsWithFilter.mutateAsync()}
							>
								<DeleteIcon />
								Remove Offline Platforms
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell align="left" width="1">Status</TableCell>
									<TableCell align="left" width="20%">Name</TableCell>
									<TableCell align="left">Properties</TableCell>
									<TableCell align="left" width="10%">Availability</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{platformsQuery.isLoading ? (
									<TableRow>
										{Array(4).fill(0).map((_, i, s) =>
											<TableCell key={`skeleton-${i}`}>
												{(i !== s.length - 1) && <Skeleton animation="wave" />}
											</TableCell>
										)}
									</TableRow>
								) : (
									platforms?.map(platform => (
										<TableRow key={platform.uuid} className={clsx(platform.unregistered && classes.unregistered)}>
											<TableCell align="left">
												<PlatformStateLabel platform={platform} />
											</TableCell>
											<TableCell>
												<Typography variant="body2">{platform.friendlyName}</Typography>
												<Typography color="text.secondary" variant="caption">{platform.uuid}</Typography>
											</TableCell>
											<TableCell align="left">
												{/* <pre style={{ whiteSpace: 'break-spaces' }}>{"{" + (platform as PlatformTypes.PlatformWithPropertiesDto)?.properties.map(p => `"${p.propertyId}": ${JSON.stringify(p.value ?? (p as any).values)}`).join(", ") + "}"}</pre> */}
												<Table size='small' padding='none' sx={{ width: 'auto', "& td": { border: 0 } }}>
													<TableBody>
														<TableRow><TableCell sx={{ fontWeight: 500, pr: 3 }}>Top Level Type</TableCell><TableCell sx={{ py: 0.5 }}><Chip label={platform.topLevelType} size='small' variant="outlined" /></TableCell></TableRow>
														{platform.properties?.map((p, i) =>
															<TableRow key={`row_${i}`}>
																<TableCell sx={{ fontWeight: 500, pr: 3 }}>
																	<Tooltip title={knownPropertyNames[p.propertyId] !== undefined ? p.propertyId : ''} arrow placement='left'>
																		<span>{knownPropertyNames[p.propertyId] ?? p.propertyId}</span>
																	</Tooltip>
																</TableCell>
																<TableCell sx={{ py: 0.5 }}><PropertyLabel property={p} /></TableCell>
															</TableRow>
														)}
													</TableBody>
												</Table>
											</TableCell>
											<TableCell align="left">{platform.accessibility === undefined ? "(unknown)" : PlatformAvailabilityLabels.get(platform.accessibility)}</TableCell>
										</TableRow>
									))
								)
								}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid >
		</StyledBox>
	)
}
const PropertyLabel: React.FC<{ property: PlatformProperty }> = (props) => {
	const { property: p } = props;
	switch (p.type) {
		case 'propertyAndReqType.simpleString':
		case 'string':
			return <Chip label={p.value} size='small' variant="outlined" />;
		case 'propertyType.multivalue':
		case 'propertyType.providesFunctions':
		case 'list':
			return p.values.map((v, i) => <Chip key={i} label={v} sx={{ mr: 1 }} size='small' variant="outlined" />);
	}

	let unknownProperty = p as any;
	return unknownProperty.value ? unknownProperty.value :
		Array.isArray(unknownProperty.values) ? unknownProperty.values.join(', ') : (unknownProperty.values || '')
}

interface PlatformStateProps {
	platform: PlatformDto
}

const PlatformStateLabels: { [state in PlatformState]: string } = {
	BUSY: "Busy",
	IDLE: "Idle",
	UNKNOWN: "Unknown",
	UNREGISTERED: "Unregistered"
};

const PlatformStateLabel: React.FC<PlatformStateProps> = (props) => {
	const { platform } = props;

	return (
		<Tooltip title={`${PlatformStateLabels[platform.unregistered ? PlatformState.UNREGISTERED : platform.state]}${!platform.connected ? ", disconnected" : ""}`} arrow placement="top">
			<div style={{ position: "relative" }} className={`${PREFIX}-${platform.unregistered ? PlatformState.UNREGISTERED : platform.state}`}>
				<div className={clsx(classes.circle, !platform.connected && classes.circleDisconnected)} />
				{platform.connected && <div className={classes.ringring} />}
			</div>
		</Tooltip>
	);
}

export default PlatformTable;
