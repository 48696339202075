import { FolderCopy } from "@mui/icons-material";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SettingsIcon from '@mui/icons-material/Settings';
import { Divider, Grid, ListItemIcon, ListItemText, MenuItem, MenuList, Paper } from "@mui/material";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

interface SingleProjectSidebarProps {
	location: string
}

const ProjectSidebarWrapper: React.FunctionComponent<SingleProjectSidebarProps> = (props) => {
	const { location } = props;

	const navigate = useNavigate();

	function isSelected(menuItem: string) {
		return menuItem === location;
	}

	return (
		<Grid container flexGrow={1} direction="row" wrap="nowrap" spacing={2} sx={{ height: "100%" }}>
			<Grid item sx={{ height: "100%" }}>
				<Paper sx={{ height: "100%", width: "260px" }}>
					<MenuList>
						<MenuItem
							selected={isSelected("cicd")}
							onClick={() => navigate("../cicd")}
						>
							<ListItemIcon>
								<AllInclusiveIcon />
							</ListItemIcon>
							<ListItemText>
								CI/CD
							</ListItemText>
						</MenuItem>
						<MenuItem
							selected={isSelected("file-browser")}
							onClick={() => navigate("../file-browser")}
						>
							<ListItemIcon>
								<ManageSearchIcon />
							</ListItemIcon>
							<ListItemText>
								File Browser
							</ListItemText>
						</MenuItem>
						<Divider />
						<MenuItem
							selected={isSelected("project-repositories")}
							onClick={() => navigate("../project-repositories")}
						>
							<ListItemIcon>
								<FolderCopy />
							</ListItemIcon>
							<ListItemText>
								Repositories
							</ListItemText>
						</MenuItem>
						<MenuItem
							selected={isSelected("settings")}
							onClick={() => navigate("../settings")}
						>
							<ListItemIcon>
								<SettingsIcon />
							</ListItemIcon>
							<ListItemText>
								Settings
							</ListItemText>
						</MenuItem>
					</MenuList>
				</Paper>
			</Grid>
			<Grid item flexGrow={1}>
				<Outlet />
			</Grid>
		</Grid>
	)
}

export default ProjectSidebarWrapper;