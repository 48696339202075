import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import App from './App';
import ContextStore from './context/ContextStore';
import './index.css';

TimeAgo.addDefaultLocale(en);

const nonReloadingResponseCode = [400, 401, 403, 404, 405];

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: (failureCount, error: any) => {
				if (nonReloadingResponseCode.indexOf(error?.response?.status) !== -1) {
					return false;
				}

				return failureCount < 3;
			},
			notifyOnChangeProps: 'tracked'
		}
	}
});

ReactDOM.render(
	<React.StrictMode>
		<ContextStore>
			<QueryClientProvider client={queryClient}>
				<App />
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</ContextStore>
	</React.StrictMode>,
	document.getElementById('root')
);
