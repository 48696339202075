import PipelineStepDto from "./PipelineStepDto";
import PipelineTriggerDto from "./PipelineTriggerDto";

export default class PipelineConfigurationDto {
	uuid: string | undefined;
	projectUuid: string | undefined;
	name: string | undefined;
	sourceBranch: string | undefined;
	steps: PipelineStepDto[] | undefined;
	triggers: PipelineTriggerDto[] | undefined;
}