import { Buffer } from 'buffer';
import ky, { HTTPError } from 'ky';
import { useContext, useMemo } from 'react';
import { IActionType } from '../context/ContextInterfaces';
import { Context } from '../context/ContextStore';
import AccessTokenResponse from '../model/authentication/AccessTokenResponse';

export default function useApiClient() {
	const [context, dispatchContext] = useContext(Context);

	return useMemo(() => ky.extend({
		hooks: {
			// Hook to attach the access token to each request sent with ky
			beforeRequest: [
				(_request, _options) => {
					_request.headers.set('Authorization', 'Bearer ' + context.accessToken);
				},
			],
			// Hook to automatically refresh the access token if a 401 occurs and the user was previously authenticated
			afterResponse: [
				async (_request, _options, response) => {
					if (response.status === 401) {
						// Try to refresh the token if a refresh token has been stored
						if (context.refreshToken) {
							console.log("Attempting to refresh the access token");
							try {
								const tokens = await ky
									.post(`${context.config?.MAS_URL}/oauth/token?grant_type=refresh_token&refresh_token=${context.refreshToken}`, {
										headers: {
											Authorization: "Basic " + Buffer.from(`${context.config?.CLIENT_ID}:${context.config?.CLIENT_SECRET}`).toString("base64"),
										},
									})
									.json<AccessTokenResponse>()
									.then((response) => {
										return Promise.resolve({
											access_token: response.access_token,
											refresh_token: response.refresh_token
										});
									});

								// Store the access tokens if existing
								if (tokens.access_token && tokens.refresh_token) {
									console.log("Successfully refreshed the access token");
									dispatchContext({ type: IActionType.SET_ACCESSTOKEN, payload: tokens.access_token });
									dispatchContext({ type: IActionType.SET_REFRESHTOKEN, payload: tokens.refresh_token });

									// Update the request and retry sending it
									_request.headers.set('Authorization', 'Bearer ' + tokens.access_token);
									return ky(_request);
								}

								console.error("Could not refresh the access token");
							} catch (e) {
								console.error("Could not refresh the access token");
							}
						} else {
							console.error('No refresh token available');
						}

						// Set an appropriate error message for the login screen
						dispatchContext({ type: IActionType.SET_AUTH_ERROR_MESSAGE, payload: 'Session expired, please log in again' });

						// Remove the (possibly) stored tokens
						dispatchContext({ type: IActionType.REMOVE_ACCESSTOKEN });
						dispatchContext({ type: IActionType.REMOVE_REFRESHTOKEN });

						// Set the authentication flag to false which would lead the user to the login screen again
						dispatchContext({ type: IActionType.SET_AUTHENTICATED, payload: false });

						// Also throw the error to the component which tried to fetch
						throw new HTTPError(response, _request, _options);
					}
				},
			],
		},
	}), [context, dispatchContext]);
}
