import { Theme } from '@mui/material';
import { createTheme, PaletteColor, PaletteColorOptions } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
	interface DefaultTheme extends Theme { }
}

declare module '@mui/material/styles' {
	interface Palette {
		green: PaletteColor;
		red: PaletteColor;
		neutral: PaletteColor;
	}

	interface PaletteOptions {
		green: PaletteColorOptions;
		red: PaletteColorOptions;
		neutral: PaletteColorOptions;
	}
}

export const theme = createTheme(({
	palette: {
		primary: { main: '#1c2d44' },
		secondary: { main: '#E0E0E0' },
		error: { main: '#F44336' },

		/* Colors used for charts */
		green: { main: '#79bd4d' },
		red: { main: '#CE4A36' },
		neutral: { main: '#C6C6C6' }
	},
}));
