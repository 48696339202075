import CeeProjectRepositoryDto from "./CeeProjectRepositoryDto";
import RepositoryMappingDto from "./CeeRepositoryMappingDto";
import ExternalRepositoryDto from "./ExternalRepositoryDto";
import PipelineConfigurationDto from "./PipelineConfigurationDto";

export default class CeeProjectDto {
	uuid: string | undefined;
	name: string | undefined;
	description: string | undefined;
	deadline: string | undefined;
	secondsUntilConsideredIdle: number | undefined;
	status: CeeProjectStatus | undefined;
	version: string | undefined;
	ownerUuid: string | undefined;
	externalRepositories: ExternalRepositoryDto[] | undefined;
	repositoryMappings: RepositoryMappingDto[] | undefined;
	projectRepositories: { [identifier: string]: CeeProjectRepositoryDto } | undefined;
	projectSecret: string | undefined;
	metadata: Map<string, string> | undefined;
	pipelineConfigurations: PipelineConfigurationDto[] | undefined;
	// Arttest legacy
	projectSettings: string | undefined;
	platformRequirementJson: string | undefined;
}

export enum CeeProjectStatus {
	AUTO,
	COMPLETED,
	IN_PROGRESS,
	IDLE
}