import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import PipelineDto from "../../model/services/project-service/PipelineDto";

const PREFIX = 'JobDetailsDialog';

const classes = {
	form: `${PREFIX}-form`,
	divider: `${PREFIX}-divider`,
	roleRadioGroup: `${PREFIX}-roleRadioGroup`,
	privilegeList: `${PREFIX}-privilegeList`,
	table: `${PREFIX}-table`
};

const StyledDialog = styled(Dialog)(() => ({
	[`& .${classes.form}`]: {
		width: '100%',
	},
	[`& .${classes.divider}`]: {
		margin: '1em',
	},
	[`& .${classes.roleRadioGroup}`]: {
		display: 'flex',
		flexDirection: 'row',
	},
	[`& .${classes.privilegeList}`]: {
		marginTop: '1em',
	},
	[`& .${classes.table}`]: {
		minWidth: 500,
	}
}));

interface PipelineDetailsDialogProps {
	pipeline: PipelineDto | undefined;
	open: boolean;
	onClose: () => void;
}

const PipelineDetailsDialog: React.FunctionComponent<PipelineDetailsDialogProps> = (props) => {
	const { pipeline, open, onClose } = props;

	const handleClose = () => {
		onClose();
	};

	return (
		<StyledDialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
			<DialogTitle>Pipeline Details</DialogTitle>
			<DialogContent sx={{ p: 0 }}>
				<TableContainer>
					<Table className={classes.table} aria-label="job-details">
						<TableBody sx={{ '& td:first-child': { fontWeight: 500 } }}>
							<TableRow>
								<TableCell align="right">UUID</TableCell>
								<TableCell align="left">{pipeline?.uuid}</TableCell>
							</TableRow>
							{/* <TableRow>
								<TableCell align="right">Status</TableCell>
								<TableCell align="left">{pipeline?.status}</TableCell>
							</TableRow> */}
							{/* <TableRow>
								<TableCell align="right">Status Message</TableCell>
								<TableCell align="left" style={{ whiteSpace: 'pre-line' }}>{pipeline?.currentCicdJob?.statusMessage}</TableCell>
							</TableRow> */}
							{/* <TableRow>
								<TableCell align="right">Repository</TableCell>
								<TableCell align="left">{pipeline?.repository}</TableCell>
							</TableRow> */}
							{/* <TableRow>
								<TableCell align="right">Branch</TableCell>
								<TableCell align="left">{pipeline?.branch}</TableCell>
							</TableRow> */}
							<TableRow>
								<TableCell align="right">Triggerer</TableCell>
								<TableCell align="left">{pipeline?.triggerer}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="right">Pipeline Configuration</TableCell>
								<TableCell align="left">${pipeline?.pipelineConfiguration?.name ?? ""}</TableCell>
							</TableRow>
							{/* <TableRow>
								<TableCell align="right">Jobs</TableCell>
								<TableCell align="left">{pipeline?.numberOfFinishedJobs} finished, {pipeline?.cicdJobs.length} total</TableCell>
							</TableRow> */}
							{/* <TableRow>
								<TableCell align="right">Creation Time</TableCell>
								<TableCell align="left">{pipeline?.creationTime ? new Date(pipeline.creationTime).toLocaleString() : ''}</TableCell>
							</TableRow> */}
							{/* <TableRow>
								<TableCell align="right">Start Time</TableCell>
								<TableCell align="left">{pipeline?.startTime ? new Date(pipeline.startTime).toLocaleString() : ''}</TableCell>
							</TableRow> */}
							{/* <TableRow>
								<TableCell align="right">End Time</TableCell>
								<TableCell align="left">{pipeline?.endTime ? new Date(pipeline.endTime).toLocaleString() : ''}</TableCell>
							</TableRow> */}
							{/* <TableRow key="report">
                <TableCell align="right">Report URL</TableCell>
                <TableCell align="left">
                  {pipeline?.reportUuid ? (
                    <a
                      href={`${context.config?.CICDSERVICE_URL}/${job?.reportUuid}`}
                    >
                      {context.config?.CICDSERVICE_URL}/{job?.reportUuid}
                    </a>
                  ) : (
                    <div>Currently not available</div>
                  )}
                </TableCell>
              </TableRow> */}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Close</Button>
			</DialogActions>
		</StyledDialog>
	);
};

export default PipelineDetailsDialog;
