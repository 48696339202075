import { Reducer } from 'react';
import { IConfig, IAction, IActionType, IContext } from './ContextInterfaces';

const ContextReducer: Reducer<IContext, IAction> = (state, action) => {
	switch (action.type) {
		case IActionType.SET_CONFIG:
			return {
				...state,
				config: action.payload as IConfig,
			};
		case IActionType.SET_AUTHENTICATED:
			return {
				...state,
				authenticated: action.payload as boolean,
			};
		case IActionType.SET_ACCESSTOKEN:
			localStorage.setItem('accessToken', action.payload || '');
			return {
				...state,
				accessToken: action.payload as string,
			};
		case IActionType.REMOVE_ACCESSTOKEN:
			localStorage.removeItem('accessToken');
			return {
				...state,
				accessToken: '',
			};
		case IActionType.SET_REFRESHTOKEN:
			localStorage.setItem('refreshToken', action.payload || '');
			return {
				...state,
				refreshToken: action.payload as string,
			};
		case IActionType.REMOVE_REFRESHTOKEN:
			localStorage.removeItem('refreshToken');
			return {
				...state,
				refreshToken: '',
			};
		case IActionType.SET_SELECTED_PAGE:
			return {
				...state,
				selectedPage: action.payload as string,
			};
		case IActionType.SET_AUTH_ERROR_MESSAGE:
			return {
				...state,
				authenticationErrorMessage: action.payload as string,
			};
		case IActionType.CLEAR_AUTH_ERROR_MESSAGE:
			return {
				...state,
				authenticationErrorMessage: undefined,
			};
		default:
			return state;
	}
};

export default ContextReducer;
