import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Grid, IconButton, styled, TableCell, TableRow } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import CeeProjectRepositoryDto from "../../../model/services/project-service/CeeProjectRepositoryDto";
import { FileTreeDto, RepoFileDto } from "../../../model/services/repo-service/RepoFileDto";

const PREFIX = "FileTableRow";

const classes = {
	expandClicked: `${PREFIX}-clicked`,
	expandNotClicked: `${PREFIX}-notClicked`
}

const StyledRow = styled(TableRow)(({ theme }) => ({
	[`& .${classes.expandClicked}`]: {
		transform: "rotate(90deg)",
		transition: "transform 0.1s ease-in-out"
	},
	[`& .${classes.expandNotClicked}`]: {
		transform: "rotate(0)",
		transition: "transform 0.1s ease-in-out"
	}
}))

interface FileTableRowProps {
	projectRepository: CeeProjectRepositoryDto,
	file: RepoFileDto,
	rootFileTree: FileTreeDto, // used to extract commit information
	baseDirectory?: string,
	depth?: number,
	onClick?: (file: RepoFileDto) => void,
	isSelected?: (file: RepoFileDto) => boolean
}

const FileTableRow: React.FunctionComponent<FileTableRowProps> = (props) => {
	const { projectRepository, file, rootFileTree, baseDirectory, depth, onClick, isSelected } = props;

	const [expanded, setExpanded] = useState(false);

	return (
		<>
			<StyledRow
				hover
				onClick={() => onClick?.(file)}
				selected={isSelected?.(file)}
			>
				<TableCell sx={{ px: 0 }}>
					<Grid container direction='row' flexWrap='nowrap' flexGrow={0} justifyContent="flex-start" alignItems="center" justifyItems="center">
						{depth && <Grid item width={`${depth * 2}em`} />}
						<Grid item>
							<IconButton
								size="small"
								sx={{ visibility: file.directory ? "visible" : "hidden" }}
								onClick={(event) => {
									event.stopPropagation();
									setExpanded((old) => !old);
								}}
							>
								<KeyboardArrowRightIcon className={clsx(expanded ? classes.expandClicked : classes.expandNotClicked)} />
							</IconButton>
						</Grid>
						<Grid item>
							<Grid container direction="row" alignItems="center" wrap="nowrap">
								{file.directory ? <FolderOutlinedIcon fontSize="small" sx={{ mr: 1 }} /> : <InsertDriveFileOutlinedIcon fontSize="small" sx={{ mr: 1 }} />}
								<Grid item>
									{file.name}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</TableCell>
			</StyledRow>
			{expanded && file.children?.map(child => <FileTableRow
				key={child.name}
				projectRepository={projectRepository}
				file={child}
				rootFileTree={rootFileTree}
				baseDirectory={`${baseDirectory ?? ""}/${file.name}/`}
				depth={(depth ?? 0) + 1}
				onClick={onClick}
				isSelected={isSelected}
			/>)}
		</>
	)
}

export default FileTableRow;