import { Button, ButtonProps } from "@mui/material";
import { useState } from "react";

interface IConfirmationButtonProps extends ButtonProps {
	confirmationLabel?: string
}

const ConfirmationButton: React.FunctionComponent<IConfirmationButtonProps> = (props) => {
	const { confirmationLabel, onClick, ...buttonProps } = props;

	const [clicked, setClicked] = useState(false);

	return (
		<Button
			{...buttonProps}
			onClick={e => {
				if (!clicked) {
					setClicked(true);
				} else {
					onClick?.(e);
					setClicked(false);
				}
			}}
			onMouseLeave={() => {
				setClicked(false);
			}}
		>
			{clicked ? (confirmationLabel || 'Confirm') : props.children}
		</Button>
	)
}

export default ConfirmationButton;