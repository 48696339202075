import { AccountCircle } from '@mui/icons-material';
import { AppBar, Button, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Toolbar } from '@mui/material';
import { styled } from '@mui/styles';
import clsx from 'clsx';
import React, { useContext, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.svg';
import { IActionType } from "../context/ContextInterfaces";
import { Context } from "../context/ContextStore";

const PREFIX = "CeeHeader";

const classes = {
	appBar: `${PREFIX}-appBar`,
	logo: `${PREFIX}-logo`,
	selectedPage: `${PREFIX}-selectedPage`,
	separator: `${PREFIX}-separator`
}

const StyledDiv = styled('div')(({ theme }) => ({
	[`& .${classes.appBar}`]: {
		height: '5em',
	},
	[`& .${classes.logo}`]: {
		height: '100%',
		paddingRight: '2em',
	},
	[`& .${classes.selectedPage}`]: {
		borderBottom: `1px solid ${theme.palette.secondary.main}`,
		borderRadius: '0px',
	},
	[`& .${classes.separator}`]: {
		flexGrow: 1
	}
}))

interface CeeHeaderProps {
	menuItems: { identifier: string, name: string }[],
}

const CeeHeader: React.FunctionComponent<CeeHeaderProps> = (props: CeeHeaderProps) => {
	const [, dispatchContext] = useContext(Context);

	const [open, setOpen] = useState(false);
	const anchorRef = useRef<HTMLButtonElement>(null);

	const navigate = useNavigate();
	const location = useLocation();

	const { menuItems } = props;

	const handleMenuToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleListKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	};

	const handleMenuClose = (target: EventTarget | null) => {
		if (anchorRef.current && anchorRef.current.contains(target as HTMLElement)) {
			return;
		}

		setOpen(false);
	};

	return (
		<StyledDiv>
			<AppBar className={classes.appBar} position="static">
				<Toolbar>
					<img src={Logo} alt="mindmotiv_logo" className={classes.logo} />
					{menuItems.map(menuItem =>
						<Button
							key={menuItem.identifier}
							className={clsx(location.pathname.indexOf(`/${menuItem.identifier}`) !== -1 ? classes.selectedPage : '')}
							color="secondary"
							onClick={() => navigate(`/${menuItem.identifier}`)}
						>
							{menuItem.name}
						</Button>
					)}
					<div className={classes.separator} />
					<IconButton color="secondary" ref={anchorRef} onClick={handleMenuToggle} size="large">
						<AccountCircle />
					</IconButton>
					<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 1 }}>
						{({ TransitionProps, placement }) => (
							<Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
								<Paper>
									<ClickAwayListener onClickAway={(event) => handleMenuClose(event.target)}>
										<MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
											<MenuItem
												onClick={() => {
													handleMenuToggle();
													// Remove stored tokens to perform a logout
													dispatchContext({ type: IActionType.SET_AUTHENTICATED, payload: false });
													dispatchContext({ type: IActionType.REMOVE_ACCESSTOKEN });
													dispatchContext({ type: IActionType.REMOVE_REFRESHTOKEN });
													navigate("/login");
												}}
											>
												Logout
											</MenuItem>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</Toolbar>
			</AppBar>
		</StyledDiv >
	)
}

export default CeeHeader;