import { Grid } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import CeeFooter from './CeeFooter';
import CeeHeader from './CeeHeader';

interface DashboardProps {
	menuItems: { identifier: string, name: string }[]
}

const Dashboard: React.FunctionComponent<DashboardProps> = (props) => {
	const { menuItems } = props;

	return (
		<Grid container direction="column" minHeight="100vh" flexWrap="nowrap">
			<CeeHeader menuItems={menuItems} />
			<Grid container flexGrow={1} direction="row">
				<Outlet />
			</Grid>
			<CeeFooter />
		</Grid>
	);
};

export default Dashboard;
