export interface IConfig {
	MAS_URL: string;
	CLIENT_ID: string;
	CLIENT_SECRET: string;
	CEE_CORE_URL: string;
	CICDSERVICE_URL: string;
	STORAGESERVICE_URL: string;
	PROJECTSERVICE_URL: string;
	LOGGINGSERVICE_URL: string;
	LOGGINGSERVICE_WS_URL: string;
	REPORTSERVICE_URL: string;
	REPOSERVICE_URL: string;
}

export interface IContext {
	config: IConfig | null | undefined;
	authenticated: boolean;
	accessToken: string;
	refreshToken?: string;
	authenticationErrorMessage?: string;
}

export enum IActionType {
	SET_CONFIG = 'SET_CONFIG',
	SET_AUTHENTICATED = 'SET_AUTHENTICATED',
	SET_ACCESSTOKEN = 'SET_ACCESSTOKEN',
	REMOVE_ACCESSTOKEN = 'REMOVE_ACCESSTOKEN',
	SET_REFRESHTOKEN = 'SET_REFRESHTOKEN',
	REMOVE_REFRESHTOKEN = 'REMOVE_REFRESHTOKEN',
	SET_SELECTED_PAGE = 'SET_SELECTED_PAGE',
	SET_AUTH_ERROR_MESSAGE = 'SET_AUTH_ERROR_MESSAGE',
	CLEAR_AUTH_ERROR_MESSAGE = 'CLEAR_AUTH_ERROR_MESSAGE'
}

export interface IAction {
	type: IActionType;
	payload?: any;
}
