import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Alert, Autocomplete, Box, Button, ButtonGroup, CircularProgress, Grid, Paper, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../context/ContextStore";
import useApiClient from '../../../hooks/useApiClient';
import PagedResult from "../../../model/services/PagedResult";
import PipelineConfigurationDto from "../../../model/services/project-service/PipelineConfigurationDto";
import PipelinesTable from "./PipelinesTable";

const SingleProjectCicd: React.FunctionComponent = () => {
	const DEFAULT_PAGE = 0;
	const DEFAULT_ROWS_PER_PAGE = 10;

	const [context,] = useContext(Context);
	const apiClient = useApiClient();
	const queryClient = useQueryClient();

	const [page, setPage] = useState(DEFAULT_PAGE);
	const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

	const [selectedPipelineConfiguration, setSelectedPipelineConfiguration] = useState<PipelineConfigurationDto>();

	const { ceeProjectUuid, pipelineConfigurationUuid } = useParams();

	const navigate = useNavigate();

	const pipelineConfigurationsQuery = useQuery(['pipeline-configurations', { ceeProjectUuid }], () => apiClient.get(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${ceeProjectUuid}/pipeline-configurations`, {
		searchParams: {
			page: page,
			size: rowsPerPage
		}
	})
		.json<PagedResult<PipelineConfigurationDto>>()
	);

	const triggerPipelineQuery = useMutation(() => apiClient.post(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${ceeProjectUuid}/pipeline-configurations/${pipelineConfigurationUuid}/trigger`), {
		onSuccess: () => queryClient.invalidateQueries(['pipelines', { pipelineConfiguration: pipelineConfigurationUuid }])
	});

	const pipelineConfigurations = pipelineConfigurationsQuery.data?.data;

	useEffect(() => {
		if (!pipelineConfigurations) {
			setSelectedPipelineConfiguration(undefined);
		}

		if (!pipelineConfigurationUuid) {
			let firstAvailableConfiguration = pipelineConfigurations?.at(0);
			setSelectedPipelineConfiguration(firstAvailableConfiguration);
			navigate(firstAvailableConfiguration?.uuid ?? "");
		}

		let selectedPipelineConfiguration = pipelineConfigurations?.filter(p => p.uuid === pipelineConfigurationUuid);
		if (selectedPipelineConfiguration?.length === 1) {
			setSelectedPipelineConfiguration(selectedPipelineConfiguration.at(0));
		}
	}, [pipelineConfigurationUuid, pipelineConfigurations, navigate]);

	if (pipelineConfigurationsQuery.error) {
		return <Grid padding={2}><Alert severity='error'>{String(pipelineConfigurationsQuery.error || '')}</Alert></Grid>;
	} else if (pipelineConfigurationsQuery.isLoading) {
		return <Grid padding={2} container justifyContent='center'><CircularProgress /></Grid>;
	}

	return <Paper>
		<Grid container direction="row" wrap="nowrap" justifyContent="space-between" padding={2}>
			<Grid item width="50%">
				<Grid container gap={2}>
					<Grid item flexGrow={1}>
						<Autocomplete
							id="project-combo"
							value={selectedPipelineConfiguration ?? new PipelineConfigurationDto()}
							options={pipelineConfigurations ?? [new PipelineConfigurationDto()]}
							getOptionLabel={option => option.name || "<No Name Specified>"}
							disablePortal
							disableClearable
							renderInput={(params) => <TextField {...params} label="Selected Configuration" />}
							onChange={(_, value) => {
								if (!value || !value.uuid) {
									return;
								}

								navigate(`/projects/${ceeProjectUuid}/cicd/${value.uuid}`);
								setPage(DEFAULT_PAGE);
								setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
							}}
							isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
							size="small"
						/>
					</Grid>
					<Grid item alignSelf="center">
						<ButtonGroup variant="outlined">
							{pipelineConfigurationUuid && <Button
								variant="outlined"
								startIcon={<EditIcon />}
								onClick={() => navigate(`/projects/${ceeProjectUuid}/cicd/${pipelineConfigurationUuid}/edit`)}
							>
								Edit
							</Button>}
							<Button
								variant="outlined"
								startIcon={<AddIcon />}
								onClick={() => navigate(`/projects/${ceeProjectUuid}/cicd/new`)}
							>
								New
							</Button>
						</ButtonGroup>
					</Grid>
				</Grid>
			</Grid>
			<Grid item alignSelf="center">
				<Button
					variant="outlined"
					size="small"
					onClick={() => triggerPipelineQuery.mutate()}
					disabled={!selectedPipelineConfiguration || triggerPipelineQuery.isLoading}
				>
					<PlayArrowIcon />
					Trigger Pipeline
				</Button>
			</Grid>
		</Grid>
		{selectedPipelineConfiguration ? <PipelinesTable selectedPipelineConfiguration={selectedPipelineConfiguration} /> : <Box textAlign="center" padding="1em">You have no pipeline configuration selected. Either select one or create a new one using the button above.</Box>}
	</Paper>;
}

export default SingleProjectCicd;