import DownloadIcon from '@mui/icons-material/Download';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { Box } from "@mui/system";
import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { Context } from '../../context/ContextStore';
import SessionLogDialog from "../../dialogs/logging-service/SessionLogDialog";
import useApiClient from '../../hooks/useApiClient';
import PagedResult from '../../model/services/PagedResult';
import SessionDto from "../../model/services/logging-service/SessionDto";

const SessionsTable: React.FunctionComponent = () => {
	const DEFAULT_PAGE = 0;
	const DEFAULT_ROWS_PER_PAGE = 25;


	const [context,] = useContext(Context);
	const apiClient = useApiClient();

	const [page, setPage] = useState(DEFAULT_PAGE);
	const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

	const [selectedSession, setSelectedSession] = useState<SessionDto | undefined>(undefined);
	const [sessionLogDialogOpen, setSessionLogDialogOpen] = useState(false);

	const sessionsQuery = useQuery(["sessions"], () => apiClient
		.get(`${context.config?.LOGGINGSERVICE_URL}/api/v1/sessions`, {
			searchParams: {
				page: page,
				size: rowsPerPage,
			}
		})
		.json<PagedResult<SessionDto>>(),
		{
			refetchInterval: 2000,
		}
	);

	const sessionsCount = sessionsQuery.data?.count;
	const sessions = Array.from(sessionsQuery.data?.data ?? [])
		.sort((a, b) => {
			if (!a.closed && b.closed) {
				return -1;
			} else if (a.closed && !b.closed) {
				return 1;
			} else {
				return new Date(b.creation ?? "").getTime() - new Date(a.creation ?? "").getTime()
			}
		});

	function handleChangePage(_: unknown, newPage: number) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
		setRowsPerPage(Number(event.target.value));
		setPage(0);
	}

	function handleDownloadForSessionWithid(session: SessionDto) {
		apiClient
			.get(`${context.config?.LOGGINGSERVICE_URL}/api/v1/sessions/${session.id}/logs/download`, { headers: { "Content-Type": "text/plain" } })
			.then(response => response.blob())
			.then(blob => {
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement('a');
				a.href = url;
				a.download = `log-${session.logEmitterType}${session.logEmitterName ? `-${session.logEmitterName}` : ""}-${session.creation}.log`;
				document.body.appendChild(a);
				a.click();
				a.remove();
			});
	}

	return (
		<Box padding="1em" width="100%">
			<TableContainer component={Paper}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="left">ID</TableCell>
							<TableCell align="left">Log Emitter Type</TableCell>
							<TableCell align="left">Log Emitter Name</TableCell>
							<TableCell align="left">Log Emitter ID</TableCell>
							<TableCell align="left">Creation</TableCell>
							<TableCell align="left">Closed</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{sessionsQuery.isLoading ? (
							<TableRow>
								{Array(7).fill(0).map((_, i, s) =>
									<TableCell key={`skeleton-${i}`}>
										{(i !== s.length - 1) && <Skeleton animation="wave" />}
									</TableCell>
								)}
							</TableRow>
						) : (
							sessions?.map(session => (
								<TableRow key={session.id}>
									<TableCell>{session.id}</TableCell>
									<TableCell>{session.logEmitterType}</TableCell>
									<TableCell>{session.logEmitterName}</TableCell>
									<TableCell>{session.logEmitterId}</TableCell>
									<TableCell>{session.creation !== undefined ? new Date(session.creation).toUTCString() : "Unkown"}</TableCell>
									<TableCell>{session.closed ? "Closed" : "Open"}</TableCell>
									<TableCell>
										<Grid container direction='row' flexWrap='nowrap' flexGrow={0} justifyContent="flex-end">
											<Grid item>
												<Tooltip title="Attach to log">
													<IconButton
														size="small"
														onClick={() => {
															setSelectedSession(session);
															setSessionLogDialogOpen(true);
														}}
													>
														<WysiwygIcon />
													</IconButton>
												</Tooltip>
											</Grid>
											<Grid item>
												<Tooltip title="Download">
													<IconButton
														size="small"
														onClick={() => handleDownloadForSessionWithid(session)}
													>
														<DownloadIcon />
													</IconButton>
												</Tooltip>
											</Grid>
											{/* <Grid item>
												<Tooltip title="Info">
													<IconButton
														size="small"
														onClick={() => {
														}}
													>
														<InfoOutlinedIcon />
													</IconButton>
												</Tooltip>
											</Grid> */}
										</Grid>
									</TableCell>
								</TableRow>
							))
						)
						}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[10, 25, 50, 100]}
								colSpan={7}
								count={sessionsCount ?? 0}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
								style={{ borderBottom: "0px" }}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
			{selectedSession !== undefined && <SessionLogDialog open={sessionLogDialogOpen} session={selectedSession} onClose={() => setSessionLogDialogOpen(false)} />}
		</Box >
	)
}

export default SessionsTable;