import { Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ButtonProps } from '@mui/material/Button';
import clsx from 'clsx';
import React from 'react';

const PREFIX = 'ProgressButton';

const classes = {
	buttonWrapper: `${PREFIX}-buttonWrapper`,
	buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledButton = styled(Button)(() => ({
	[`& .${classes.buttonWrapper}`]: { position: 'relative' },

	[`& .${classes.buttonProgress}`]: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	}
}));

interface ProgressButtonProps extends ButtonProps {
	showProgress: boolean;
	dontDisableOnProgress?: boolean;
}

const ProgressButton: React.FunctionComponent<ProgressButtonProps> = (props) => {
	const { disabled, showProgress, dontDisableOnProgress, className, ...otherProps } = props;

	return (
		<StyledButton disabled={disabled || (showProgress && !dontDisableOnProgress)} className={clsx(classes.buttonWrapper, props.className || '')} {...otherProps}>
			{props.children}
			{showProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
		</StyledButton>
	);
};

export default ProgressButton;
