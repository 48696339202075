import AddIcon from '@mui/icons-material/Add';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	Alert,
	Autocomplete,
	Button,
	Checkbox,
	CircularProgress,
	Collapse, Grid, IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../../../context/ContextStore';
import useApiClient from '../../../hooks/useApiClient';
import PipelineConfigurationDto from "../../../model/services/project-service/PipelineConfigurationDto";
import PipelineStepDto, { StepType } from "../../../model/services/project-service/PipelineStepDto";
import PipelineTriggerDto from "../../../model/services/project-service/PipelineTriggerDto";
import ConfirmationButton from '../../ConfirmationButton';
import EditableTextField from "../../EditableTextField";
import PipelineTriggerEditor from './PipelineTriggerEditor';

const availableCicdActions = [
	{ name: "Refresh Project", command: "refresh-project" }];

interface SingleProjectCicdSettingsProps {
	newProject: boolean;
}

const SingleProjectCicdSettings: React.FunctionComponent<SingleProjectCicdSettingsProps> = (props) => {

	const [context,] = useContext(Context);
	const apiClient = useApiClient();

	const navigate = useNavigate();

	const { newProject } = props;

	const { ceeProjectUuid, pipelineConfigurationUuid } = useParams();

	const pipelineConfigurationQuery = useQuery(['pipeline-configuration', { pipelineConfigurationUuid }], () => apiClient.get(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${ceeProjectUuid}/pipeline-configurations/${pipelineConfigurationUuid}`)
		.json<PipelineConfigurationDto>(),
		{
			enabled: !newProject || !!pipelineConfigurationUuid
		}
	)

	const pipelineConfiguration = pipelineConfigurationQuery.data;

	const [projectName, setProjectName] = useState('');
	const [triggers, setTriggers] = useState<PipelineTriggerDto[]>([]);
	const [steps, setSteps] = useState<PipelineStepDto[]>([]);
	const [sourceBranch, setSourceBranch] = useState('');

	const [temporaryStep, setTemporaryStep] = useState<PipelineStepDto>();
	const [temporaryStepsArgumentString, setTemporaryStepsArgumentString] = useState("");

	const [projectNameTouched, setProjectNameTouched] = useState(false);
	const [sourceBranchTouched, setSourceBranchTouched] = useState(false);

	const [projectNameError, setProjectNameError] = useState(false);
	const [sourceBranchError, setSourceBranchError] = useState(false);
	const [argumentsError, setArgumentsError] = useState(false);

	const queryClient = useQueryClient();

	// Resets the form on re-opening
	useEffect(() => {
		setProjectName('');
		setTriggers([]);
		setSteps([]);
		setSourceBranch('');

		setProjectNameTouched(false);
		setSourceBranchTouched(false);

		setProjectNameError(false);
		setSourceBranchError(false);
		setArgumentsError(false);

		setTemporaryStep(undefined);

		if (pipelineConfiguration) {
			setProjectName(pipelineConfiguration.name ?? "");
			setSourceBranch(pipelineConfiguration.sourceBranch ?? "");
			setTriggers(pipelineConfiguration.triggers ?? []);
			setSteps(pipelineConfiguration.steps ?? []);
			setSourceBranch(pipelineConfiguration.sourceBranch ?? "main");
		}
	}, [pipelineConfiguration]);

	// Checks for errors in the input fields
	useEffect(() => {
		setProjectNameError(projectNameTouched && projectName.length === 0);
		setSourceBranchError(sourceBranchTouched && sourceBranch.length === 0);
	}, [projectName, projectNameTouched, sourceBranchTouched, sourceBranch.length])

	const createPipelineConfiguration = useMutation((pipelineConfigurationToCreate: PipelineConfigurationDto) => apiClient.post(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${ceeProjectUuid}/pipeline-configurations`, { json: pipelineConfigurationToCreate })
		.json<PipelineConfigurationDto>(),
		{
			onSuccess: (response) => {
				queryClient.invalidateQueries(['pipeline-configurations']);
			}
		}
	)

	const editPipelineConfiguration = useMutation((pipelineConfigurationToEdit: PipelineConfigurationDto) => apiClient.patch(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${ceeProjectUuid}/pipeline-configurations/${pipelineConfigurationToEdit.uuid}`, { json: pipelineConfigurationToEdit, searchParams: { replace: true } })
		.json<PipelineConfigurationDto>(),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['pipeline-configuration', { pipelineConfiguration: pipelineConfigurationUuid }]);
			}
		}
	)

	const deletePipelineConfiguration = useMutation((pipelineConfigurationToDelete: PipelineConfigurationDto) => apiClient.delete(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${ceeProjectUuid}/pipeline-configurations/${pipelineConfigurationToDelete.uuid}`),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['pipeline-configuration', { pipelineConfiguration: pipelineConfigurationUuid }]);
				navigate(`../`);
			}
		}
	)

	function handleDelete(): void {
		if (pipelineConfiguration) {
			deletePipelineConfiguration.mutate(pipelineConfiguration);
		}
	}

	async function handleSave(): Promise<void> {
		if (!ceeProjectUuid) {
			return;
		}

		let temporaryPipelineConfiguration = new PipelineConfigurationDto();
		temporaryPipelineConfiguration.projectUuid = ceeProjectUuid || '';
		temporaryPipelineConfiguration.name = projectName;
		temporaryPipelineConfiguration.sourceBranch = sourceBranch;
		temporaryPipelineConfiguration.triggers = triggers;
		temporaryPipelineConfiguration.steps = steps;

		if (pipelineConfiguration) {
			temporaryPipelineConfiguration.uuid = pipelineConfiguration.uuid;
			editPipelineConfiguration.mutate(temporaryPipelineConfiguration, {
				onSuccess: (result) => {
					result.steps?.sort((a, b) => (a.stepIndex ?? 0) - (b.stepIndex ?? 0)).forEach((step, index, array) => {
						if (index === 0) {
							return;
						}

						step.dependentStepIds = [array[index - 1].id ?? -1];
					})

					// Do the final mutation if steps have been created correctly
					editPipelineConfiguration.mutate(result);
				}
			});
		} else {
			createPipelineConfiguration.mutate(temporaryPipelineConfiguration, {
				onSuccess: (result) => {
					result.steps?.sort((a, b) => (a.stepIndex ?? 0) - (b.stepIndex ?? 0)).forEach((step, index, array) => {
						if (index === 0) {
							return;
						}

						step.dependentStepIds = [array[index - 1].id ?? -1];
					})

					// Do the final mutation if steps have been created correctly
					editPipelineConfiguration.mutate(result, {
						onSuccess: (response) => {
							navigate(`../${response.uuid}`);
						}
					});
				}
			});
		}
	}

	const isLoading = pipelineConfigurationQuery.isLoading || createPipelineConfiguration.isLoading || editPipelineConfiguration.isLoading || deletePipelineConfiguration.isLoading;
	const isError = pipelineConfigurationQuery.isError || createPipelineConfiguration.isError || editPipelineConfiguration.isError || deletePipelineConfiguration.isError;
	const error = pipelineConfigurationQuery.error || createPipelineConfiguration.error || editPipelineConfiguration.error || deletePipelineConfiguration.error;

	return (
		<Box component='form' noValidate autoComplete='off' overflow='auto'>
			<Grid container direction='column' spacing={2} padding={2}>
				<Grid item><Typography variant="h5">{newProject ? "Add Pipeline Configuration" : `Edit Pipeline Configuration`}</Typography></Grid>
				<Grid item style={{ paddingTop: "0px", paddingBottom: "0px" }}>
					<Collapse in={isError}><Alert severity="error">{String(error || '')}</Alert></Collapse>
					<Collapse in={editPipelineConfiguration.isSuccess}><Alert severity="success">Changes saved!</Alert></Collapse>
				</Grid>
				<Grid item alignSelf='center' style={{ paddingTop: "0px", paddingBottom: "0px" }}>
					<Collapse in={pipelineConfigurationQuery.isLoading}><CircularProgress color="primary" /></Collapse>
				</Grid>
				{(newProject || pipelineConfigurationQuery.isSuccess) && <>
					<Grid item>
						<TextField
							id='project-name'
							label='Name'
							sx={{ width: '100%' }}
							value={projectName}
							onChange={(event) => {
								setProjectNameTouched(true);
								setProjectName(event.target.value);
							}}
							error={projectNameError}
							disabled={isLoading}
							required
						/>
					</Grid>
					<Grid item>
						<TextField
							id='source-branch'
							label='Branch'
							sx={{ width: '100%' }}
							value={sourceBranch}
							onChange={(event) => {
								setSourceBranchTouched(true);
								setSourceBranch(event.target.value);
							}}
							error={sourceBranchError}
							disabled={isLoading}
							required
						/>
					</Grid>
					<Grid item>
						<Paper>
							<Grid container direction="column">
								<Typography variant="h6" sx={{ pt: 1, px: 2 }}>Triggers</Typography>
								<Typography variant="body2" color="gray" sx={{ pb: 1, px: 2 }}>{`${context.config?.PROJECTSERVICE_URL}/api/v1/webhooks/[jenkins/gitlab/github]/${pipelineConfiguration?.uuid}`}</Typography>
								<PipelineTriggerEditor value={triggers} onChange={e => setTriggers(e)} />
							</Grid>
						</Paper>
					</Grid>
					<Grid item>
						<Paper>
							<Grid container direction="column">
								<Grid item>
									<Typography variant="h6" sx={{ pt: 1, px: 2 }}>Actions</Typography>
								</Grid>
								<Grid item>
									<TableContainer>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell width="20%">Name</TableCell>
													<TableCell width="5%">Predefined</TableCell>
													<TableCell width="35%">Command</TableCell>
													<TableCell>Arguments</TableCell>
													<TableCell width="1" />
												</TableRow>
											</TableHead>
											<TableBody>
												{steps && steps.sort((a, b) => (a.stepIndex ?? 0) - (b.stepIndex ?? 0)).map((step, index, arr) =>
													<TableRow key={step.stepIndex}>
														<TableCell>
															<EditableTextField
																value={step.name ?? ""}
																onSave={(newName) => {
																	setSteps(steps
																		.map(oldStep => {
																			if (oldStep.stepIndex === step.stepIndex) {
																				oldStep.name = newName;
																			}
																			return oldStep;
																		}));
																	return Promise.resolve();
																}}
															/>
														</TableCell>
														<TableCell>
															<Checkbox checked={step.type === StepType.SPECIFIC} onChange={(newValue) => setSteps((oldSteps) => {
																return oldSteps.map(oldStep => {
																	if (oldStep.stepIndex === step.stepIndex) {
																		oldStep.type = newValue.target.checked ? StepType.SPECIFIC : StepType.GENERIC;
																	}
																	return oldStep;
																})
															})} />
														</TableCell>
														<TableCell>
															{(step.type === StepType.SPECIFIC) ? <EditableTextField
																value={step.command ?? ""}
																onSave={(newCommand) => {
																	setSteps(steps
																		.map(oldStep => {
																			if (oldStep.stepIndex === step.stepIndex) {
																				oldStep.command = newCommand;
																			}
																			return oldStep;
																		}));
																	return Promise.resolve();
																}}
																renderInput={(_, tempValue, setTempValue) => <Autocomplete
																	options={availableCicdActions.map(action => action.command)}
																	size="small"
																	renderInput={(params) => <TextField {...params} label="Command" />}
																	getOptionLabel={(option) => {
																		let match = availableCicdActions.filter(action => action.command === option);
																		if (match.length === 1) {
																			return match[0].name;
																		}
																		return "";
																	}}
																	value={tempValue}
																	onChange={(_, value) => {
																		let match = availableCicdActions.filter(action => action.command === value);
																		if (match.length === 1) {
																			setTempValue(value);
																		}
																	}}
																	disableClearable
																/>}
																renderValue={(value) => availableCicdActions.filter(action => action.command === value)[0]?.name ?? value}
															/> : <EditableTextField
																value={step.command ?? ""}
																onSave={(newCommand) => {
																	try {
																		setSteps(steps
																			.map(oldStep => {
																				if (oldStep.stepIndex === step.stepIndex) {
																					oldStep.command = newCommand;
																				}
																				return oldStep;
																			}));
																		return Promise.resolve();
																	} catch {
																		return Promise.reject();
																	}
																}}
																inputValidator={(value) => value !== ""}
															/>}
														</TableCell>
														<TableCell>
															<EditableTextField
																value={JSON.stringify(step.arguments)}
																onSave={(newArguments) => {
																	try {
																		setSteps(steps
																			.map(oldStep => {
																				if (oldStep.stepIndex === step.stepIndex) {
																					oldStep.arguments = JSON.parse(newArguments === "" ? "{}" : newArguments);
																				}
																				return oldStep;
																			}));
																		return Promise.resolve();
																	} catch {
																		return Promise.reject();
																	}
																}}
																inputValidator={(input) => {
																	if (input === "") {
																		return true;
																	}

																	try {
																		JSON.parse(input);
																		return true;
																	} catch {
																		return false;
																	}
																}}
																fullWidth
															/>
														</TableCell>
														<TableCell width="1">
															<Grid container direction="row" wrap="nowrap">
																<Grid item>
																	<IconButton
																		size="small"
																		onClick={() => {
																			setSteps((oldSteps) => {
																				let newSteps = [...oldSteps];

																				oldSteps.forEach((oldStep, i, _) => {
																					if (oldStep.stepIndex === step.stepIndex) {
																						let oldStepIndex = oldStep.stepIndex ?? 0;
																						newSteps[i].stepIndex = oldStepIndex - 1;
																						newSteps[i - 1].stepIndex = oldStepIndex;
																					}
																				});

																				return newSteps;
																			})
																		}}
																		disabled={index === 0}
																	>
																		<ArrowUpwardIcon />
																	</IconButton>
																</Grid>
																<Grid item>
																	<IconButton
																		size="small"
																		onClick={() => {
																			setSteps((oldSteps) => {
																				let newSteps = [...oldSteps];

																				oldSteps.forEach((oldStep, i, _) => {
																					if (oldStep.stepIndex === step.stepIndex) {
																						let oldStepIndex = oldStep.stepIndex ?? 0;
																						newSteps[i].stepIndex = oldStepIndex + 1;
																						newSteps[i + 1].stepIndex = oldStepIndex;
																					}
																				});

																				return newSteps;
																			})
																		}}
																		disabled={index === arr.length - 1}
																	>
																		<ArrowDownwardIcon />
																	</IconButton>
																</Grid>
																<Grid item>
																	<IconButton
																		size="small"
																		onClick={() => {
																			setSteps(steps.filter(oldStep => oldStep.stepIndex !== step.stepIndex));
																		}}
																	>
																		<DeleteIcon />
																	</IconButton>
																</Grid>
															</Grid>
														</TableCell>
													</TableRow>
												)}
												<TableRow>
													<TableCell>
														<TextField
															label="Name"
															variant="standard"
															size="small"
															value={temporaryStep?.name ?? ""}
															onChange={event => setTemporaryStep((previous) => {
																if (previous === undefined) {
																	let newStep = new PipelineStepDto();
																	newStep.name = event.target.value;
																	newStep.allowedToFail = false;
																	newStep.stepIndex = (steps.length === 0 ? 0 : (steps.reduce((prevStep, step) => (prevStep.stepIndex ?? 0) > (step.stepIndex ?? 0) ? prevStep : step).stepIndex ?? 0) + 1);
																	return newStep;
																}

																return { ...previous, name: event.target.value };
															})}
														/>
													</TableCell>
													<TableCell>
														<Checkbox checked={temporaryStep?.type === StepType.SPECIFIC ?? false} onChange={(newValue) => setTemporaryStep((previous) => {
															if (previous === undefined) {
																let newStep = new PipelineStepDto();
																newStep.type = newValue.target.checked ? StepType.SPECIFIC : StepType.GENERIC;
																newStep.allowedToFail = false;
																newStep.stepIndex = (steps.length === 0 ? 0 : (steps.reduce((prevStep, step) => (prevStep.stepIndex ?? 0) > (step.stepIndex ?? 0) ? prevStep : step).stepIndex ?? 0) + 1);
																return newStep;
															}

															return { ...previous, type: newValue.target.checked ? StepType.SPECIFIC : StepType.GENERIC };
														})} />
													</TableCell>
													<TableCell>
														{temporaryStep?.type === StepType.SPECIFIC ? <Autocomplete
															options={availableCicdActions.map(action => action.command)}
															size="small"
															renderInput={(params) => <TextField {...params} label="Command" />}
															getOptionLabel={(option) => {
																let match = availableCicdActions.filter(action => action.command === option);
																if (match.length === 1) {
																	return match[0].name;
																}
																return "";
															}}
															value={temporaryStep?.command || ""}
															onChange={(event, value) => {
																let match = availableCicdActions.filter(action => action.command === value);
																if (match.length === 1) {
																	setTemporaryStep((previous) => {
																		if (previous === undefined) {
																			let newStep = new PipelineStepDto();
																			newStep.command = value;
																			newStep.allowedToFail = false;
																			newStep.stepIndex = (steps.length === 0 ? 0 : (steps.reduce((prevStep, step) => (prevStep.stepIndex ?? 0) > (step.stepIndex ?? 0) ? prevStep : step).stepIndex ?? 0) + 1);
																			return newStep;
																		}

																		return { ...previous, command: value };
																	});
																}
															}}
															disableClearable
														/> : <TextField
															label="Command"
															variant="standard"
															size="small"
															fullWidth
															error={temporaryStep?.command === ""}
															value={temporaryStep?.command || ""}
															onChange={event => setTemporaryStep((previous) => {
																if (previous === undefined) {
																	let newStep = new PipelineStepDto();
																	newStep.command = event.target.value;
																	newStep.allowedToFail = false;
																	newStep.stepIndex = (steps.length === 0 ? 0 : (steps.reduce((prevStep, step) => (prevStep.stepIndex ?? 0) > (step.stepIndex ?? 0) ? prevStep : step).stepIndex ?? 0) + 1);
																	return newStep;
																}

																return { ...previous, command: event.target.value };
															})}
														/>
														}
													</TableCell>
													<TableCell>
														<TextField
															label="Arguments"
															variant="standard"
															size="small"
															fullWidth
															error={argumentsError}
															value={temporaryStepsArgumentString || ""}
															onChange={event => {
																setTemporaryStepsArgumentString(event.target.value);

																try {
																	let newArguments = JSON.parse(event.target.value);
																	setArgumentsError(false);
																	setTemporaryStep((previous) => {
																		if (previous === undefined) {
																			let newStep = new PipelineStepDto();
																			newStep.arguments = newArguments;
																			newStep.allowedToFail = false;
																			newStep.stepIndex = (steps.length === 0 ? 0 : steps.reduce((prevStep, step) => (prevStep.stepIndex ?? 0) > (step.stepIndex ?? 0) ? prevStep : step).stepIndex ?? 0) + 1;
																			return newStep;
																		}

																		return { ...previous, arguments: newArguments };
																	});
																} catch (e) {
																	setArgumentsError(true);
																}
															}}
														/>
													</TableCell>
													<TableCell>
														<IconButton
															size="small"
															onClick={() => {
																if (temporaryStep) {
																	setSteps((oldSteps) => [...oldSteps, temporaryStep]);
																	setTemporaryStep(undefined);
																	setTemporaryStepsArgumentString("");
																}
															}}
															disabled={argumentsError}
														>
															<AddIcon />
														</IconButton>
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					<Grid item>
						<Grid container direction='row' gap={2} justifyContent="flex-end">
							{!newProject && pipelineConfiguration && <ConfirmationButton variant="outlined" color='error' confirmationLabel="Really?" onClick={handleDelete} disabled={isLoading}>Delete</ConfirmationButton>}
							<Button variant="contained" onClick={() => handleSave()} disabled={isLoading}>{newProject ? "Create" : "Update"}</Button>
						</Grid>
					</Grid>
				</>}
			</Grid>
		</Box >
	)
};

export default SingleProjectCicdSettings;
