import { CssBaseline, styled, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { Box } from "@mui/system";
import React, { useContext } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { StompSessionProvider } from "react-stomp-hooks";
import Dashboard from './components/Dashboard';
import JobsTable from "./components/jobs/JobsTable";
import Login from './components/Login';
import NoConfigFound from './components/NoConfigFound';
import PageNotFound from './components/PageNotFound';
import PlatformTable from './components/platforms/PlatformsTable';
import SingleProjectCicd from "./components/projects/cicd/SingleProjectCicd";
import SingleProjectCicdSettings from './components/projects/cicd/SingleProjectCicdSettings';
import ProjectFileBrowser from "./components/projects/files/ProjectFileBrowser";
import ProjectSidebarWrapper from "./components/projects/ProjectSidebarWrapper";
import SingleProjectRepositories from "./components/projects/repositories/SingleProjectRepositories";
import SingleProjectSettings from "./components/projects/settings/SingleProjectSettings";
import ProjectSelector from "./components/ProjectSelector";
import SessionsTable from "./components/sessions/SessionsTable";
import { Context } from './context/ContextStore';
import './theme/ReactResizable.scss';
import { theme } from './theme/Theme';

const StyledDiv = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'column',
	height: '100vh',
	boxSizing: 'border-box',
}));

const availablePages = [
	{
		identifier: "projects",
		name: "Projects"
	},
	{
		identifier: "platforms",
		name: "Platforms"
	},
	{
		identifier: "jobs",
		name: "Jobs"
	},
	{
		identifier: "monitoring",
		name: "Monitoring"
	}
]

const App: React.FunctionComponent = () => {
	const [context] = useContext(Context);

	return (
		<StyledDiv>
			<CssBaseline />
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					{context.config ? (
						<BrowserRouter basename="/">
							<Routes>
								<Route path="login" element={<Login />} />
								{context.authenticated ? (
									<Route element={<Dashboard menuItems={availablePages} />}>
										{/* Main routes */}
										<Route path="projects" element={<ProjectSelector />}>
											<Route index element={<Box textAlign={"center"} padding={"1em"}> It seems that no project exits. Feel free to create one with the button above.</Box>} />
											<Route path=":ceeProjectUuid">
												<Route index element={<Navigate to="cicd" />} />
												<Route path="cicd" element={<ProjectSidebarWrapper location="cicd" />}>
													<Route index element={<SingleProjectCicd />} />
													<Route path=":pipelineConfigurationUuid">
														<Route index element={<SingleProjectCicd />} />
														<Route path="edit" element={<SingleProjectCicdSettings newProject={false} />} />
													</Route>
													<Route path="new" element={<SingleProjectCicdSettings newProject={true} />} />
													<Route path="*" element={<Navigate to="cicd" />} />
												</Route>
												<Route path="file-browser" element={<ProjectSidebarWrapper location="file-browser" />}>
													<Route index element={<ProjectFileBrowser />} />
												</Route>
												<Route path="settings" element={<ProjectSidebarWrapper location="settings" />}>
													<Route index element={<SingleProjectSettings newProject={false} />} />
												</Route>
												<Route path="project-repositories" element={<ProjectSidebarWrapper location="project-repositories" />}>
													<Route index element={<SingleProjectRepositories />} />
												</Route>
												<Route path="*" element={<Navigate to="cicd" />} />
											</Route>
											<Route path="new" element={<SingleProjectSettings newProject={true} />} />
										</Route>
										{/* Infrastructure routes */}
										<Route path="platforms" element={<PlatformTable />} />
										<Route path="jobs" element={<JobsTable />} />
										<Route path="monitoring" element={
											<StompSessionProvider url={context.config?.LOGGINGSERVICE_WS_URL ?? ""} connectHeaders={{ "client-type": "log-observer" }}>
												<SessionsTable />
											</StompSessionProvider>
										} />
										{/* Fallback routes */}
										<Route path="/" element={<Navigate to="projects" />} />
										<Route path="*" element={<PageNotFound />} />
									</Route>
								) : (
									<Route path="*" element={<Navigate to="login" />} />
								)}
							</Routes>
						</BrowserRouter>
					) : (
						// If config is 'undefined' then the config.json could NOT be loaded (if it's 'null' it's just not loaded yet)
						context.config === undefined && <NoConfigFound />
					)}
				</ThemeProvider>
			</StyledEngineProvider>
		</StyledDiv >
	);
};

export default App;
