import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Grid, IconButton, InputAdornment, TextField, TextFieldProps, Typography } from "@mui/material";
import React, { KeyboardEvent, useCallback, useRef, useState } from "react";
import { useMutation } from 'react-query';

type EditableTextFieldProps = TextFieldProps & {
	value: string;
	onSave: (newValue: string) => Promise<void>;
	inputValidator?: (input: string) => boolean;
	renderInput?: (oldValue: string, tempValue: string, setTempValue: (newValue: string) => void) => JSX.Element;
	renderValue?: (value: string) => string;
	editModeEnabledInitially?: boolean;
}

const EditableTextField: React.FC<EditableTextFieldProps> = (props) => {
	const { value, onSave, inputValidator, renderInput, renderValue, editModeEnabledInitially, ...otherProps } = props;

	const [editModeEnabled, setEditModeEnabled] = useState(editModeEnabledInitially ?? false);
	const [tempValue, setTempValue] = useState(value);
	// const [saving, setSaving] = useState(false);

	const ref = useRef<HTMLInputElement>(null);

	const handleCancel = useCallback(() => setEditModeEnabled(false), []);

	// const handleSave = useCallback(() => {
	// 	setSaving(true);
	// 	onSave(tempValue).then(() => {
	// 		setEditModeEnabled(false);
	// 	}).catch(() => {
	// 		setError(true);
	// 	}).finally(() => {
	// 		setSaving(false);
	// 	})
	// }, [onSave, tempValue]);

	const save = useMutation((newValue: string) => onSave(newValue), {
		onSuccess: () => setEditModeEnabled(false)
	});

	const handleSave = useCallback(() => {
		save.mutate(tempValue);
	}, [save, tempValue]);

	const saving = save.isLoading;

	const internalRenderValue = useCallback((input: string) => {
		let value = renderValue ? renderValue(input) : input;
		if (!value?.trim()) {
			value = "\u00a0";
		}
		return value;
	}, [renderValue])

	function renderButtonsAndProgress(): JSX.Element {
		return saving ?
			<CircularProgress color="primary" size="1em" /> :
			<Grid container direction="row" wrap="nowrap">
				<IconButton
					onClick={handleCancel}
				>
					<CloseIcon color="error" />
				</IconButton>
				<IconButton
					onClick={handleSave}
					disabled={saving}
				>
					<CheckIcon color="success" />
				</IconButton>
			</Grid>
	}

	const onKeyPress = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			handleSave();
		}
	}, [handleSave]);

	const error = save.isError || (inputValidator?.(tempValue) ?? false);

	return (
		<>
			{editModeEnabled ?
				renderInput !== undefined ?
					<Grid container direction="row" wrap="nowrap">
						<Grid item width="100%">
							{renderInput(value, tempValue, (newValue) => setTempValue(newValue))}
						</Grid>
						<Grid item>
							{renderButtonsAndProgress()}
						</Grid>
					</Grid> :
					<TextField
						variant="standard"
						size="small"
						value={tempValue}
						autoFocus
						onBlur={() => {
							if (value === tempValue) {
								setEditModeEnabled(false)
							} else {
								setTimeout(() => {
									ref.current?.focus();
								}, 0)
							}
						}}
						InputProps={{
							endAdornment:
								<InputAdornment position="end">
									{renderButtonsAndProgress()}
								</InputAdornment>,
							style: {
								fontSize: "0.875rem"
							},
							inputProps: {
								ref: ref,
								onKeyPress: onKeyPress
							}
						}}
						onChange={(e) => {
							setTempValue(e.target.value)
						}}
						disabled={saving}
						error={error}
						{...otherProps}
					/> :
				<Typography variant="body2"
					onClick={() => {
						setEditModeEnabled(true);
						setTempValue(value);
					}}
					sx={{
						cursor: "pointer",
						"&:hover": {
							textDecoration: "underline",
						},
						wordWrap: "break-word",
						wordBreak: "break-all",
						display: "inline-block",
						width: '100%'
					}}
				>
					{internalRenderValue(value)}
				</Typography>}
		</>
	)
}

export default EditableTextField;