import { Box, Link, styled, Typography } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
	backgroundColor: `${theme.palette.primary.main}`,
	color: 'white',
	paddingTop: '1em',
	paddingBottom: '1em',
}));

const CeeFooter: React.FunctionComponent = () => {
	return (
		<StyledBox>
			<Typography variant="body2" align="center">
				{'© '}
				<Link color="inherit" href="https://mindmotiv.com/" target="_blank">
					Mindmotiv GmbH
				</Link>{' '}
				{new Date().getFullYear()}
			</Typography>
		</StyledBox>
	);
};

export default CeeFooter;
