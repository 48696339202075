import React, { useContext, useEffect, useState } from "react";
import { useSubscription } from "react-stomp-hooks";
import { Context } from "../../context/ContextStore";
import useApiClient from '../../hooks/useApiClient';
import LogDto from "../../model/services/logging-service/LogDto";

interface SessionLogProps {
	sessionId: string,
	onMessage?: () => void
}

const SessionLog: React.FunctionComponent<SessionLogProps> = (props) => {
	const [context,] = useContext(Context);
	const apiClient = useApiClient();

	const { sessionId, onMessage } = props;

	const MESSAGE_COUNT = 1000;

	const [messages, setMessages] = useState<LogDto[]>([]);

	useSubscription(`/queue/sessions/${sessionId}/logs`, (message) => {
		try {
			const m = JSON.parse(message.body) as LogDto;
			setMessages((old) => [...old, m]);
			onMessage?.();
		} catch (error) {
			console.error(error);
		}
	});

	// This is only done once to see the whole log in the dialog
	useEffect(() => {
		apiClient.get(`${context.config?.LOGGINGSERVICE_URL}/api/v1/sessions/${sessionId}/logs/download`, { searchParams: { size: MESSAGE_COUNT }, headers: { "Content-Type": "application/json" } })
			.json<LogDto[]>()
			.then(data => {
				setMessages((old) => {
					if (old.length === 0) {
						return data;
					}

					let index = data.findIndex(e => e.id === old[0].id);

					if (index !== -1) {
						return [...data.splice(0, index), ...old];
					}

					return [...data, ...old];
				});

				onMessage?.();
			});
		// Ignore onMessage dependency, because we don't care about changed onMessage callback
		// eslint-disable-next-line
	}, [context, apiClient, sessionId]);

	return (
		<pre style={{ width: "100%", minHeight: "25em" }}>
			{messages.map(m => m.message).join("\n")}
		</pre >
	)
}

export default SessionLog;