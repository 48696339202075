import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from "@mui/material";
import { useState } from "react";

interface FailureMessageProps {
	content?: string
}

const FailureMessage: React.FunctionComponent<FailureMessageProps> = (props) => {
	const { content } = props;

	const [open, setOpen] = useState(false);

	if (!content) return <></>;

	const LIMIT = 100;

	if (content.length < LIMIT) {
		return <Typography color="error">{content}</Typography>
	}

	return <>
		{/* <Typography color="error" variant='body2'>{content.substring(0, LIMIT)}...</Typography> */}
		<Link color='error' style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
			Show Error
		</Link>
		<Dialog open={open} onClose={() => setOpen(false)} maxWidth='lg' fullWidth>
			<DialogTitle>Error Message For Job</DialogTitle>
			<DialogContent>
				<Typography color="error"><pre>{content}</pre></Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpen(false)}>Close</Button>
			</DialogActions>
		</Dialog>
	</>;
};

export default FailureMessage;