import { Box, Collapse, LinearProgress, Skeleton, styled, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip } from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { DateTime } from "luxon";
import moment from 'moment';
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";

import { KyInstance } from "ky/distribution/types/ky";
import ReactTimeAgo from "react-time-ago";
import { IContext } from "../../../context/ContextInterfaces";
import { Context } from "../../../context/ContextStore";
import PipelineDetailsDialog from "../../../dialogs/project-service/PipelineDetailsDialog";
import useApiClient from '../../../hooks/useApiClient';
import useMobile from "../../../hooks/useMobile";
import { JobDto, JobGroupDto, JobStatus } from "../../../model/cee/JobDto";
import PagedResult from "../../../model/services/PagedResult";
import PipelineConfigurationDto from "../../../model/services/project-service/PipelineConfigurationDto";
import PipelineDto from "../../../model/services/project-service/PipelineDto";
import FailureMessage from "../../FailureMessage";
import JobGroupGraph, { getJobDepth } from "../../jobs/JobGroupGraph";

const PREFIX = 'PipelinesTable';

const classes = {
	button: `${PREFIX}-button`,
	tableCell: `${PREFIX}-tablecell`,
	green: `${PREFIX}-green`,
	red: `${PREFIX}-red`,
	blue: `${PREFIX}-blue`,
	grey: `${PREFIX}-grey`
}

const StyledDiv = styled('div')(({ theme }) => ({
	display: "flex",
	flexGrow: 1,
	[`& .${classes.tableCell}`]: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		fontVariantNumeric: 'tabular-nums'
	}
}));

interface PipelinesTableProps {
	selectedPipelineConfiguration: PipelineConfigurationDto;
}

function getJobGroups(pipelines: PipelineDto[], apiClient: KyInstance, context: IContext): Promise<JobGroupDto[]> {
	if (pipelines === undefined) {
		return Promise.reject();
	}

	return Promise.all(pipelines
		.filter(pipeline => pipeline.jobGroupUuid !== undefined)
		.map(pipeline => apiClient.get(`${context.config?.CEE_CORE_URL}/api/v1/jobgroups/${pipeline.jobGroupUuid}`).json<JobGroupDto>()));
}

const PipelinesTable: React.FunctionComponent<PipelinesTableProps> = (props) => {
	const { selectedPipelineConfiguration } = props;

	const DEFAULT_PAGE = 0;
	const DEFAULT_ROWS_PER_PAGE = 25;

	const [page, setPage] = useState(DEFAULT_PAGE);
	const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

	const [selectedPipelineId] = useState<string>();

	const [pipelineDetailsDialogOpen, setPipelineDetailsDialogOpen] = useState(false);

	const [jobGroups, setJobGroups] = useState<JobGroupDto[]>([]);

	const isMobile = useMobile();

	const [context,] = useContext(Context);
	const apiClient = useApiClient();

	const pipelinesQuery = useQuery(['pipelines', { pipelineConfiguration: selectedPipelineConfiguration.uuid }], () => apiClient.get(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${selectedPipelineConfiguration!.projectUuid}/pipelines`, {
		searchParams: {
			page: page,
			size: rowsPerPage,
			sort: 'created,desc',
			pipelineConfigurationUuid: selectedPipelineConfiguration.uuid ?? ""
		}
	}).json<PagedResult<PipelineDto>>(),
		{
			refetchInterval: 2000,
			enabled: selectedPipelineConfiguration !== undefined,
			onSuccess: (pagedPipelines) => {
				getJobGroups(pagedPipelines.data, apiClient, context).then((jobGroups) => setJobGroups(jobGroups));
			}
		});

	const getJobGroupForPipeline = useCallback((pipeline: PipelineDto) => {
		return jobGroups.filter(jg => jg.uuid === pipeline.jobGroupUuid)[0] as (JobGroupDto | undefined);
	}, [jobGroups])

	// const pipelines = useMemo(() => [...pipelinesQuery.data?.data ?? []].sort((a, b) => moment(getJobGroupForPipeline(b)?.created ?? 0).diff(getJobGroupForPipeline(a)?.created ?? 0)) ?? [], [pipelinesQuery.data]);
	// const pipelines = useMemo(() => pipelinesQuery.data?.data ?? [], [pipelinesQuery.data]);
	const pipelines = pipelinesQuery.data?.data;
	const allPipelinesCount = pipelines?.length ?? 0;
	const selectedPipeline = pipelines?.filter(p => p.uuid === selectedPipelineId)[0];

	// const cancelPipeline = useMutation((pipelineToCancel: PipelineDto) => apiClient.post(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${selectedProject!.uuid}/pipelines/${pipelineToCancel.uuid}/cancel`),
	// 	{ onSuccess: () => queryClient.invalidateQueries(['pipelines', { pipelineConfiguration: selectedProject.uuid }]) });

	// const deletePipeline = useMutation((pipelineToDelete: PipelineDto) =>
	// 	apiClient.delete(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${selectedProject!.uuid}/pipelines/${pipelineToDelete.uuid}`),
	// 	{ onSuccess: () => queryClient.invalidateQueries(['pipelines', { pipelineConfiguration: selectedProject.uuid }]) });

	function handleChangePage(_: unknown, newPage: number) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
		setRowsPerPage(Number(event.target.value));
		setPage(0);
	}

	// function calculateProgress(numberOfFinishedJobs: number, totalJobs: number): number {
	// 	if (numberOfFinishedJobs < 0 || totalJobs < 0) {
	// 		return 0;
	// 	}

	// 	if (numberOfFinishedJobs < totalJobs) {
	// 		return numberOfFinishedJobs + 1;
	// 	}

	// 	return numberOfFinishedJobs;
	// }

	// function calculateDuration(pipeline: PipelineDto) {
	// 	if (pipeline.status === CicdPipelineStatus.Canceled || pipeline.status === CicdPipelineStatus.Canceling) {
	// 		return "-";
	// 	}

	// 	if (!pipeline.startTime) {
	// 		return "-";
	// 	}

	// 	let start = DateTime.fromISO(pipeline.startTime);
	// 	let end = DateTime.now();

	// 	if (pipeline.endTime) {
	// 		end = DateTime.fromISO(pipeline.endTime);
	// 	}

	// 	return end.diff(start).toFormat('hh:mm:ss');
	// }

	return (
		<StyledDiv>
			<TableContainer>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="left" width="1"></TableCell>
							<TableCell align="left" width="1">Status</TableCell>
							{/* {!isMobile && <TableCell align="left" width="150px">Progress</TableCell>} */}
							{/* {!isMobile && <TableCell align="left" width="250px">Current Job</TableCell>} */}
							<TableCell align="left" width="auto">Details</TableCell>
							{!isMobile && <TableCell align="left" width="auto">Trigger</TableCell>}
							{!isMobile && <TableCell align="left" width="1px">Start Time</TableCell>}
							<TableCell align="left" width="1px">Duration</TableCell>
							{/* {!isMobile && <TableCell align="left" width="1px">Traceability</TableCell>} */}
							{/* <TableCell align="left" width="1px">Report</TableCell> */}
							<TableCell align="left" width="1px" />
						</TableRow>
					</TableHead>
					<TableBody>
						{(pipelinesQuery.isLoading) ? (
							<TableRow>
								{Array(isMobile ? 6 : 7).fill(0).map((_, i, s) => {
									return <TableCell key={`skeleton-${i}`}>
										{(i !== 0 && i !== s.length - 1) && <Skeleton animation="wave" />}
									</TableCell>
								}
								)}
							</TableRow>
						) : (
							pipelines?.map((pipeline) => {
								let jobGroup = getJobGroupForPipeline(pipeline);
								return jobGroup && <JobGroupRows pipeline={pipeline} jobGroup={jobGroup} key={pipeline.uuid} />;
							}))}
						{/* <TableRow key={pipeline.uuid}>
										<TableCell className={classes.tableCell} align="left">#{pipeline.uuid}</TableCell>
										<TableCell className={classes.tableCell} align="left">
											<CicdPipelineStatusBadge status={pipeline.status} isMobile={isMobile} />
										</TableCell>
										{!isMobile && <TableCell className={classes.tableCell} align="left">
											<LinearProgress variant="determinate" value={(pipeline.numberOfFinishedJobs ?? 0) / pipeline.cicdJobs.length * 100} />
										</TableCell>}
										{!isMobile && <TableCell className={classes.tableCell} align="left">
											[{calculateProgress(pipeline.numberOfFinishedJobs ?? 0, pipeline.cicdJobs.length)}/{pipeline.cicdJobs.length}]: {pipeline.currentCicdJob?.name}
										</TableCell>}
										<TableCell className={classes.tableCell} style={{ whiteSpace: 'normal' }} align="left">
											<Typography variant="body2" sx={{ fontWeight: 500 }}>{pipeline.currentCicdJob?.name}</Typography>
											<Typography variant="body2">{pipeline.currentCicdJob?.statusMessage}</Typography>
											{pipeline.currentCicdJob?.errorMessage && <ErrorDialogButton error={pipeline.currentCicdJob?.errorMessage} />}
										</TableCell>
										{!isMobile && <TableCell className={classes.tableCell} align="left">
											<Grid container direction="column" alignContent="start" justifyContent="start">
												<Grid item>
													{pipeline.repository}
												</Grid>
												<Grid item>
													{pipeline.triggerer}
												</Grid>
											</Grid>
										</TableCell>}
										{!isMobile && <TableCell className={classes.tableCell} align="left">
											{new Date(pipeline.startTime).toLocaleString()}
										</TableCell>}
										<TableCell className={classes.tableCell} align="left">{calculateDuration(pipeline)}</TableCell>
										{!isMobile && <TableCell className={classes.tableCell} align="left">
											<Grid container direction='row' flexWrap='nowrap' flexGrow={0}>
												<Grid item>
													<IconButton
														disabled={pipeline.cicdJobs.filter(j => traceabilityStatsPresent(j.results)).length === 0}
														onClick={() => {
															setSelectedPipelineId(pipeline.id);
															setTraceabilityStatsOpen(true);
														}}
														size="large"
													>
														<PieChartIcon />
													</IconButton>
												</Grid>
												<Grid item>
													<IconButton
														disabled={pipeline.cicdJobs.filter(j => j.results.TRACEABILITY_HEATMAP).length === 0}
														onClick={() => {
															setSelectedPipelineId(pipeline.id);
															setTraceabilityHeatmapOpen(true);
														}}
														size="large"
													>
														<TableRowsIcon />
													</IconButton>
												</Grid>
											</Grid>
										</TableCell>}
										<TableCell className={classes.tableCell} align="left">
											<Grid container direction='row' flexWrap='nowrap' flexGrow={0}>
												<Grid item>
													<IconButton
														disabled={pipeline.cicdJobs.filter(j => j.results.UUID_REPORT).length === 0}
														onClick={() => handleReportView(pipeline.cicdJobs.filter(j => j.results.UUID_REPORT)[0].results.UUID_REPORT)}
														size="large"
													>
														<VisibilityIcon />
													</IconButton>
												</Grid>
												<Grid item>
													<IconButton
														disabled={pipeline.cicdJobs.filter(j => j.results.UUID_REPORT).length === 0}
														onClick={() => handleReportZipDownload(pipeline.cicdJobs.filter(j => j.results.UUID_REPORT)[0].results.UUID_REPORT)}
														size="large"
													>
														<CloudDownloadIcon />
													</IconButton>
												</Grid>
											</Grid>
										</TableCell>
										<TableCell className={classes.tableCell}>
											<Grid container direction='row' flexWrap='nowrap' flexGrow={0} justifyContent="flex-end">
												<Grid item>
													<IconButton
														size="small"
														onClick={() => {
															setSelectedPipelineId(pipeline.id);
															setPipelineDetailsDialogOpen(true);
														}}
													>
														<InfoOutlinedIcon />
													</IconButton>
												</Grid>
												<Grid item>
													{(pipeline.canCancel) &&
														<IconButton
															size="small"
															onClick={() => {
																cancelPipeline.mutate(pipeline);
															}}
														>
															<BlockIcon />
														</IconButton>
													}
													{(pipeline.canDelete) &&
														<IconButton
															size="small"
															onClick={() => {
																deletePipeline.mutate(pipeline);
															}}
														>
															<DeleteIcon />
														</IconButton>
													}
												</Grid>
											</Grid>
										</TableCell>
									</TableRow> */}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[25, 50, 100]}
								colSpan={isMobile ? 5 : 7}
								count={allPipelinesCount}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
								style={{ borderBottom: "0px" }}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
			<PipelineDetailsDialog pipeline={selectedPipeline} open={pipelineDetailsDialogOpen} onClose={() => { setPipelineDetailsDialogOpen(false) }} />
		</StyledDiv >
	)
}

interface JobGroupRowsProps {
	pipeline: PipelineDto
	jobGroup: JobGroupDto
}

const JobGroupRows: React.FunctionComponent<JobGroupRowsProps> = props => {
	const { pipeline, jobGroup } = props;

	const isMobile = useMobile();

	const [selectedJob, setSelectedJob] = useState<JobDto | undefined>();

	const sortedJobs = useMemo(() => {
		let sorted = [...jobGroup?.jobs ?? []];
		sorted.sort((a, b) => getJobDepth(a, jobGroup) - getJobDepth(b, jobGroup));
		return sorted;
	}, [jobGroup])

	return <React.Fragment key={jobGroup.uuid}>
		<TableRow sx={{ borderTop: '3px solid lightgray', '& td': { borderBottomWidth: 0 } }}>
			<TableCell colSpan={3}>
				<JobGroupGraph jobGroup={jobGroup} onJobSelected={job => setSelectedJob(job)} />
			</TableCell>
			{!isMobile && <TableCell>{pipeline.triggerer}</TableCell>}
			{!isMobile && <TableCell>{/* Start Time */}</TableCell>}
			<TableCell />
			<TableCell />
		</TableRow>
		{sortedJobs.map(job => <TableRow key={job.uuid} className={`${((sortedJobs.length ?? 0) > 1) ? 'job-group' : ''}`} selected={selectedJob?.uuid === job.uuid}>
			<TableCell />
			<TableCell className={getClassName(job.jobStatus)}>{job.jobStatus}</TableCell>
			{/* <TableCell>
											{job.ownerDisplayName !== undefined ? job.ownerDisplayName : "(id: " + job.ownerUserId + ")"}
										</TableCell> */}
			<TableCell width="20%">
				<Box sx={{ mb: 1 }}>
					{job.displayName && <Box sx={{ fontWeight: 'medium' }}>{job.displayName}</Box>}
					<div>{hasFinallyFinished(job.jobStatus) && !job.failureMessage ? 'Done' : job.currentActivity}</div>
					{job.jobStatus === "WAITING" && (job.dependencies?.length ?? 0) > 0 && <div>Waiting for {job.dependencies?.length} job{job.dependencies?.length !== 1 ? 's' : ''} to finish...</div>}
					{job.failureMessage && <FailureMessage content={job.failureMessage} />}
				</Box>
				<Collapse in={!hasFinallyFinished(job.jobStatus) && job.jobStatus !== "WAITING"}><LinearProgress variant={job.progress === 0 && !job.estimatedCompletion ? "indeterminate" : "determinate"} value={Math.min(Math.max(job.progress ?? 0, 0), 1) * 100} /></Collapse>
			</TableCell>
			<TableCell />{/* Trigger */}
			{/* <TableCell align={job.created ? "left" : "center"}>
											{job.created != null
												? <Tooltip placement="top" arrow title={DateTime.fromISO(job.created).toFormat("yyyy-MM-dd, HH:mm:ss")}><span><ReactTimeAgo date={new Date(job.created)} tooltip={false} /></span></Tooltip>
												: '-'}
										</TableCell> */}
			<TableCell sx={{ whiteSpace: 'nowrap' }} align={job.executed ? "left" : "center"}>
				{job.executed != null
					? <Tooltip placement="top" arrow title={DateTime.fromISO(job.executed).toFormat("yyyy-MM-dd, HH:mm:ss")}><span><ReactTimeAgo date={new Date(job.executed)} tooltip={false} /></span></Tooltip>
					: '-'}</TableCell>
			<TableCell sx={{ whiteSpace: 'nowrap' }} >
				{hasFinallyFinished(job.jobStatus)
					? (job.finished != null ? <span>{job.executed != null && moment.duration(moment(job.finished).diff(job.executed)).humanize()}</span> : '-')
					: (job.estimatedCompletion ? <ReactTimeAgo date={Math.max(new Date().getTime() + 10000, new Date(job.estimatedCompletion).getTime())} /> : '-')}
			</TableCell>
			<TableCell />
			{/* <TableCell>
											{job.platformDisplayName ?? ""}
											<Typography color="text.secondary" variant="subtitle2">{job.platformId ?? ""}</Typography>
										</TableCell> */}
			{/* <TableCell>{Object.entries(job.files ?? {}).map(([key, file], index) =>
											<div key={index}>
												{key}: <a href={`${(file as JobFile).storageServiceBaseUrl}/files/${(file as JobFile).fileId}`}>{(file as JobFile).name ?? (file as JobFile).fileId}</a>
											</div>
										)}
										</TableCell> */}
		</TableRow>)}
		{/* <TableRow><TableCell colSpan={isMobile ? 6 : 8} sx={{ height: '1rem', background: 'black' }} /></TableRow> */}
	</React.Fragment>;
}

function hasFinallyFinished(jobStatus: JobStatus | undefined): React.ReactNode {
	return jobStatus === JobStatus.ERROR_FINAL || jobStatus === JobStatus.FINISHED || jobStatus === JobStatus.CANCELED;
}

function getClassName(status: string | undefined) {
	switch (status) {
		case JobStatus.RUNNING:
			return classes.blue;
		case JobStatus.CANCELED:
			return classes.grey;
		case JobStatus.FINISHED:
			return classes.green;
		case JobStatus.ERROR_FINAL:
		case JobStatus.ERROR_RETRY:
			return classes.red;
		default:
			return '';
	}

}
// interface ErrorDialogButtonProps {
// 	error?: string
// }

// const ErrorDialogButton: React.FunctionComponent<ErrorDialogButtonProps> = props => {
// 	const { error } = props;

// 	const [open, setOpen] = useState(false);
// 	return <>
// 		<Link color='error' style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
// 			Show Error
// 		</Link>
// 		<Dialog open={open} maxWidth='lg' fullWidth>
// 			<DialogTitle>Error Message For Pipeline</DialogTitle>
// 			<DialogContent>
// 				<Typography color="error"><pre>{error}</pre></Typography>
// 			</DialogContent>
// 			<DialogActions>
// 				<Button onClick={() => setOpen(false)}>Close</Button>
// 			</DialogActions>
// 		</Dialog>
// 	</>
// };

export default PipelinesTable;