import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Alert, Autocomplete, Box, CircularProgress, Grid, IconButton, Paper, Skeleton, styled, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Layouts, Responsive, WidthProvider } from "react-grid-layout";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Context } from "../../../context/ContextStore";
import useApiClient from '../../../hooks/useApiClient';
import CeeProjectDto from "../../../model/services/project-service/CeeProjectDto";
import { FileTreeDto, RepoFileDto } from "../../../model/services/repo-service/RepoFileDto";
import Utils from "../../../utils/Utils";
import FileTableRow from "./FileTableRow";

import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

const PREFIX = "ProjectFileBrowser";

const classes = {
	expandClicked: `${PREFIX}-clicked`,
	expandNotClicked: `${PREFIX}-notClicked`,
	collapsed: `${PREFIX}-collapsed`
}

const StyledBox = styled(Box)(({ theme }) => ({
	[`& .${classes.expandClicked}`]: {
		transform: "rotate(90deg)",
		transition: "transform 0.1s ease-in-out"
	},
	[`& .${classes.expandNotClicked}`]: {
		transform: "rotate(0)",
		transition: "transform 0.1s ease-in-out"
	},
	[`& .${classes.collapsed}`]: {
		borderBottomColor: "transparent",
		borderBottomWidth: 0,
		transition: "border-color 0.3s ease-in-out, border-width 0s ease-in-out 0.3s"
	}
}))

const initialLayouts: Layouts = {
	lg: [
		{ i: "overview", x: 0, y: 0, w: 6, h: 4 },
		{ i: "upload", x: 6, y: 0, w: 6, h: 4 },
		{ i: "browse", x: 0, y: 6, w: 6, h: 4 },
		{ i: "details", x: 6, y: 6, w: 6, h: 4 }
	]
};

const ResponsiveGridLayout = WidthProvider(Responsive);

const ProjectFileBrowser: React.FunctionComponent = () => {
	const { ceeProjectUuid } = useParams();

	const [context,] = useContext(Context);
	const apiClient = useApiClient();

	const [selectedFile, setSelectedFile] = useState<RepoFileDto>();
	const [selectedRef, setSelectedRef] = useState("");

	const [selectedSidebarTab, setSelectedSidebarTab] = useState("general");

	const [layouts, setLayouts] = useState<Layouts>(getFromLS() ?? initialLayouts);

	const queryClient = useQueryClient();

	const baseDirectory = "/";

	const projectQuery = useQuery(["cee-project", { ceeProjectUuid }], () => apiClient.get(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${ceeProjectUuid}`)
		.json<CeeProjectDto>()
	);

	const project = projectQuery.data;

	const repoIdentifier = Object.keys(project?.projectRepositories ?? {})[0];
	const projectRepository = project?.projectRepositories?.[repoIdentifier];

	const refsQuery = useQuery(["project-repo-refs", { repoName: projectRepository?.name ?? "" }], () => apiClient.get(`${context.config?.REPOSERVICE_URL}/api/v1/repos/${projectRepository?.name}/refs`)
		.json<string[]>(),
		{
			enabled: !!projectRepository
		}
	);

	const refs = refsQuery.data?.filter((ref) => ref !== "HEAD").map((ref) => ref.substring(ref.lastIndexOf("/") + 1));

	useEffect(() => {
		if (refs !== undefined && refs.length !== 0 && selectedRef === "") {
			setSelectedRef(refs[0]);
		}
	}, [refs, selectedRef])

	const filesQuery = useQuery(['project-files', { ceeProjectUuid, repoIdentifier, selectedRef }], () => apiClient.get(`${projectRepository!.baseUrl}/api/v1/repos/${projectRepository!.name}/files${baseDirectory}`, {
		searchParams: {
			recursive: true,
			log: true,
			ref: selectedRef
		}
	}).json<FileTreeDto>(),
		{
			enabled: !!projectRepository && !!selectedRef
		}
	)

	const fileTree = filesQuery.data;
	const files = fileTree?.content;

	const selectedFileCommit = fileTree?.commits[selectedFile?.lastCommitId ?? ""];

	const notFoundError = (filesQuery.error as any)?.response?.status === 404;

	if (filesQuery.error && !notFoundError) {
		return <Grid padding={2}><Alert severity='error'>{String(filesQuery.error || '')}</Alert></Grid>;
	} else if (filesQuery.isLoading) {
		return <Grid padding={2} container justifyContent='center'><CircularProgress /></Grid>;
	}

	// function handleDownload(file: RepoFileDto) {
	// 	apiClient
	// 		.get(`${projectRepository!.baseUrl}/api/v1/repos/${projectRepository!.name}/download${baseDirectory ?? "/"}${file.name}`)
	// 		.then(file => file.blob())
	// 		.then(blob => {
	// 			var url = window.URL.createObjectURL(blob);
	// 			var a = document.createElement('a');
	// 			a.href = url;
	// 			a.download = file.name;
	// 			document.body.appendChild(a);
	// 			a.click();
	// 			a.remove();
	// 		});
	// }

	function onLayoutChange(_: any, allLayouts: Layouts) {
		setLayouts(allLayouts);
		saveToLS(allLayouts);
	}

	function getFromLS(): Layouts | undefined {
		let ls: Layouts = {};

		if (localStorage) {
			try {
				let raw = localStorage.getItem("browser-layout");
				if (!raw) {
					return undefined;
				}

				ls = JSON.parse(raw);
			} catch (e) {
				return undefined;
			}
		}

		return ls;
	}

	function saveToLS(layouts: Layouts): void {
		if (localStorage) {
			localStorage.setItem("browser-layout", JSON.stringify(layouts));
		}
	}

	return (
		<StyledBox>
			<ResponsiveGridLayout
				className="layout"
				layouts={layouts}
				cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
				rowHeight={60}
				onLayoutChange={onLayoutChange}
			>
				<Paper key="browse">
					<Box sx={{ p: 2 }}>
						<Typography variant="h6">Browse</Typography>
					</Box>
					<Autocomplete
						options={refs || []}
						value={selectedRef}
						onChange={(_, value) => {
							setSelectedRef(value ?? "");
							queryClient.invalidateQueries(['project-files', { ceeProjectUuid, repoIdentifier, selectedRef }]);
						}}
						renderInput={(params) => <TextField {...params} label="Branch" />}
						sx={{ px: 2, pb: 2 }}
						size="small"
					/>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell sx={{ px: 0 }}>
									{/* This button is useless and just corrects the indentation */}
									<IconButton
										size="small"
										sx={{ visibility: "hidden", height: "1px" }}
									>
										<KeyboardArrowRightIcon />
									</IconButton>
									Name
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{filesQuery.isLoading ? (
								<TableRow>
									{Array(6).fill(0).map((_, i, s) =>
										<TableCell key={`skeleton-${i}`}>
											{(i !== s.length - 1) && <Skeleton animation="wave" />}
										</TableCell>
									)}
								</TableRow>
							) : (
								files?.map(report => <FileTableRow
									key={report.name}
									projectRepository={projectRepository!}
									file={report}
									rootFileTree={fileTree!}
									baseDirectory={baseDirectory}
									onClick={(file) => {
										setSelectedFile(file);
									}}
									isSelected={(file) => selectedFile === file}
								/>)
							)}
						</TableBody>
					</Table>
				</Paper>
				<Paper key="details" sx={{ p: 2 }}>
					<Grid container direction="column">
						<Grid item sx={{ px: 2, pt: 2 }}>
							<Grid item>
								<Grid container direction="row" alignItems="center">
									{selectedFile?.directory ? <FolderOutlinedIcon /> : <InsertDriveFileOutlinedIcon />}
									<Typography variant="h6" sx={{ ml: 1 }}>{selectedFile?.name}</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<Tabs value={selectedSidebarTab} onChange={(_, value) => setSelectedSidebarTab(value)}>
								<Tab value="general" label="General" />
								<Tab value="commit" label="Commit" />
							</Tabs>
							{selectedSidebarTab === "general" &&
								<Box>
									<Table size="small">
										<TableBody>
											<TableRow>
												<TableCell align="right" width={1}>Name</TableCell>
												<TableCell align="left">{selectedFile?.name}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell align="right" width={1}>Size</TableCell>
												<TableCell align="left">{Utils.niceBytes(selectedFile?.size ?? 0)}</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</Box>}
							{selectedSidebarTab === "commit" &&
								<Box>
									<Table size="small">
										<TableBody>
											<TableRow>
												<TableCell align="right" width={"20%"}>Revision</TableCell>
												<TableCell align="left">{selectedFileCommit?.name ?? ""}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell align="right" width={"20%"}>Changed By</TableCell>
												<TableCell align="left">{`${selectedFileCommit?.authorName ?? ""} <${selectedFileCommit?.authorEmail ?? ""}>`}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell align="right" width={"20%"}>Date</TableCell>
												<TableCell align="left">{new Intl.DateTimeFormat("en-US", { dateStyle: "medium", timeStyle: "medium" }).format(new Date((selectedFileCommit?.commitTime ?? 0) * 1000))}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell align="right" width={"20%"}>Commit Message</TableCell>
												<TableCell align="left">{selectedFileCommit?.fullMessage ?? ""}</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</Box>}
						</Grid>
					</Grid>
				</Paper>
			</ResponsiveGridLayout>
		</StyledBox >
	);
};

export default ProjectFileBrowser;