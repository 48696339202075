import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Grid, IconButton, Paper, Skeleton, styled, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { $enum } from "ts-enum-util";
import { Context } from "../../../context/ContextStore";
import AddEditRepositoryDialog from "../../../dialogs/project-service/AddEditRepositoryDialog";
import useApiClient from '../../../hooks/useApiClient';
import PagedResult from "../../../model/services/PagedResult";
import ExternalRepositoryDto, { ExternalRepositoryType } from "../../../model/services/project-service/ExternalRepositoryDto";

const StyledBox = styled(Box)(() => ({
	padding: '1em',
	display: 'flex',
	flexGrow: 1
}));

const SORT_REPOSITORIES = (a: ExternalRepositoryDto, b: ExternalRepositoryDto) => {
	if (a.name === undefined) {
		return 1;
	}

	if (b.name === undefined) {
		return -1;
	}

	if (a.name > b.name) {
		return 1;
	}

	if (a.name < b.name) {
		return -1;
	}

	return 0;
}

interface RepositoryTableProps {
	ceeProjectUuid: string | undefined
}

const RepositoryTable: React.FunctionComponent<RepositoryTableProps> = (props) => {
	const DEFAULT_PAGE = 0;
	const DEFAULT_ROWS_PER_PAGE = 25;

	const { ceeProjectUuid } = props;

	const [context,] = useContext(Context);
	const apiClient = useApiClient();

	const [page, setPage] = useState(DEFAULT_PAGE);
	const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

	const [selectedRepository, setSelectedRepository] = useState<ExternalRepositoryDto>();
	const [AddEditRepositoryDialogOpen, setAddEditRepositoryDialogOpen] = useState(false);

	// const queryClient = useQueryClient();

	const projectRepositoriesQuery = useQuery(["project-repositories", { ceeProjectUuid }], () => apiClient
		.get(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${ceeProjectUuid}/repositories`, {
			searchParams: {
				page: page,
				size: rowsPerPage
			}
		})
		.json<PagedResult<ExternalRepositoryDto>>()
		.then(res => {
			res.data.sort(SORT_REPOSITORIES);
			return res;
		}),
		{
			enabled: !!ceeProjectUuid
		}
	);

	const repositories = projectRepositoriesQuery.data?.data;
	const allRepositoriesCount = projectRepositoriesQuery.data?.count;

	// const deleteRepository = useMutation((ceeRepositoryToDelete: ExternalRepositoryDto) => apiClient.delete(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${projectUuid}/repositories/${ceeRepositoryToDelete?.id}`),
	// 	{
	// 		onSuccess: () => {
	// 			queryClient.invalidateQueries(["project-repositories", { projectUuid: projectUuid }])
	// 			queryClient.invalidateQueries(["project-repository-mappings", { projectUuid: projectUuid }]);
	// 		}
	// 	}
	// );

	function handleChangePage(_: unknown, newPage: number) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
		setRowsPerPage(Number(event.target.value));
		setPage(0);
	}

	return (
		<StyledBox>
			<Grid container direction="column">
				<Grid item paddingBottom="1em">
					<Grid container direction="row" justifyContent="space-between">
						<Grid item>
							<Typography variant="h5">Repositories</Typography>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								startIcon={<AddIcon />}
								onClick={() => { setAddEditRepositoryDialogOpen(true) }}
							>
								Connect Repository
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell align="left" width="25%">Name</TableCell>
									<TableCell align="left" width="1">Type</TableCell>
									<TableCell align="left">URL</TableCell>
									<TableCell align="left" width="1">Branch</TableCell>
									<TableCell />
								</TableRow>
							</TableHead>
							<TableBody>
								{projectRepositoriesQuery.isLoading ? (
									<TableRow>
										{Array(5).fill(0).map((_, i, s) =>
											<TableCell key={`skeleton-${i}`}>
												{(i !== s.length - 1) && <Skeleton animation="wave" />}
											</TableCell>
										)}
									</TableRow>
								) : (
									repositories?.map(repository =>
										<TableRow key={repository.id}>
											<TableCell align="left">{repository.name}</TableCell>
											<TableCell align="left">{$enum(ExternalRepositoryType).getKeyOrDefault(repository.type, "unknown")}</TableCell>
											<TableCell align="left">{repository.url}</TableCell>
											<TableCell align="left">{repository.branch}</TableCell>
											<TableCell>
												<Grid container direction='row' flexWrap='nowrap' flexGrow={0} justifyContent="flex-end">
													<Grid item>
														<IconButton
															size="small"
															onClick={() => {
																setSelectedRepository(repository);
																setAddEditRepositoryDialogOpen(true);
															}}
														>
															<EditIcon />
														</IconButton>
													</Grid>
													{/* <Grid item>
														<IconButton
															size="small"
															onClick={() => deleteRepository.mutate(repository)}
														>
															<DeleteIcon />
														</IconButton>
													</Grid> */}
												</Grid>
											</TableCell>
										</TableRow>
									)
								)
								}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[25, 50, 100]}
										colSpan={5}
										count={allRepositoriesCount ?? 0}
										rowsPerPage={rowsPerPage}
										page={page}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										ActionsComponent={TablePaginationActions}
										style={{ borderBottom: "0px" }}
									/>
								</TableRow>
							</TableFooter>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
			<AddEditRepositoryDialog projectUuid={ceeProjectUuid} existingRepository={selectedRepository} open={AddEditRepositoryDialogOpen} onClose={() => { setAddEditRepositoryDialogOpen(false); setSelectedRepository(undefined) }} />
		</StyledBox >
	);
}

export default RepositoryTable;