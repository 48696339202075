import DownloadIcon from '@mui/icons-material/Download';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from "@mui/material";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import SessionLog from "../../components/sessions/SessionLog";
import { Context } from "../../context/ContextStore";
import useApiClient from '../../hooks/useApiClient';
import SessionDto from "../../model/services/logging-service/SessionDto";

interface SessionLogDialogProps {
	session: SessionDto;
	open: boolean;
	onClose: () => void;
}

const SessionLogDialog: React.FunctionComponent<SessionLogDialogProps> = (props) => {

	const [context,] = useContext(Context);
	const apiClient = useApiClient();

	const { session, open, onClose } = props;

	const [autoScrollingEnabled, setAutoScrollingEnabled] = useState(true);
	const contentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setAutoScrollingEnabled(true);
	}, [open])

	const onMessage = useCallback(() => {
		if (autoScrollingEnabled) {
			contentRef.current!.scrollTop = contentRef.current!.scrollHeight;
		}
	}, [autoScrollingEnabled]);

	const onScroll = useCallback(() => {
		let isBottom = contentRef.current!.scrollHeight - contentRef.current!.clientHeight - contentRef.current!.scrollTop <= 10;
		setAutoScrollingEnabled(isBottom);
	}, []);

	function handleDownloadForSessionWithid() {
		apiClient
			.get(`${context.config?.LOGGINGSERVICE_URL}/api/v1/sessions/${session.id}/logs/download`, { headers: { "Content-Type": "text/plain" } })
			.then(response => response.blob())
			.then(blob => {
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement('a');
				a.href = url;
				a.download = `log-${session.logEmitterType}${session.logEmitterName ? `-${session.logEmitterName}` : ""}-${session.creation}.log`;
				document.body.appendChild(a);
				a.click();
				a.remove();
			});
	}

	return (
		<Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
			<DialogTitle>
				<Grid container direction="row" justifyContent="space-between">
					<Grid item>
						Logs: {session.logEmitterName} ({session.logEmitterType})
					</Grid>
					<Grid item>
						<Tooltip title="Download">
							<IconButton
								size="small"
								onClick={() => handleDownloadForSessionWithid()}
							>
								<DownloadIcon />
							</IconButton>
						</Tooltip>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent ref={contentRef} onScroll={onScroll}>
				<SessionLog onMessage={onMessage} sessionId={session.id} />
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Close</Button>
			</DialogActions>
		</Dialog >
	);
};

export default SessionLogDialog;