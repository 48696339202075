import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Context } from "../../../context/ContextStore";
import useApiClient from "../../../hooks/useApiClient";
import PagedResult from "../../../model/services/PagedResult";
import CeeProjectDto from "../../../model/services/project-service/CeeProjectDto";
import RepositoryMappingDto from "../../../model/services/project-service/CeeRepositoryMappingDto";
import ExternalRepositoryDto from "../../../model/services/project-service/ExternalRepositoryDto";

interface AddRepositoryMappingDialogProps {
	ceeProjectUuid: string | undefined;
	open: boolean;
	onClose: () => void;
}

const AddRepositoryMappingDialog: React.FC<AddRepositoryMappingDialogProps> = (props) => {
	const DEFAULT_PAGE = 0;
	const DEFAULT_ROWS_PER_PAGE = 25;

	const [context,] = useContext(Context);
	const apiClient = useApiClient();
	const queryClient = useQueryClient();

	const { ceeProjectUuid, open, onClose } = props;

	// const [page, setPage] = useState(DEFAULT_PAGE);
	// const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

	const [selectedRepositories, setSelectedRepositories] = useState<ExternalRepositoryDto[]>([]);
	const [locked, setLocked] = useState(false);

	useEffect(() => {
		setLocked(false);
		setSelectedRepositories([]);
	}, [open])

	const projectQuery = useQuery(["cee-project", { ceeProjectUuid }], () => apiClient.get(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${ceeProjectUuid}`)
		.json<CeeProjectDto>(),
		{
			enabled: !!ceeProjectUuid
		}
	);

	const ceeProject = projectQuery.data;

	const repositoryQuery = useQuery(["project-repositories", { ceeProjectUuid }], () => apiClient
		.get(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${ceeProjectUuid}/repositories`, { searchParams: { page: DEFAULT_PAGE, size: DEFAULT_ROWS_PER_PAGE } })
		.json<PagedResult<ExternalRepositoryDto>>(),
		{
			enabled: !!ceeProjectUuid
		}
	);

	const addRepositoryMapping = useMutation((repositoryMapping: RepositoryMappingDto) => apiClient
		.post(`${context.config?.PROJECTSERVICE_URL}/api/v1/projects/${ceeProjectUuid}/repositoryMappings`, { json: repositoryMapping })
		.json<ExternalRepositoryDto>(),
		{
			onSuccess: () => queryClient.refetchQueries(["project-repository-mappings", { projectUuid: ceeProjectUuid }])
		}
	)

	const repositories = repositoryQuery.data?.data;

	function handleSave(): void {
		try {
			setLocked(true);

			selectedRepositories.map(selectedRepository => {
				let newMapping = new RepositoryMappingDto();
				newMapping.active = true;
				newMapping.externalRepositoryId = selectedRepository.id;
				newMapping.projectRepositoryIdentifier = ceeProject?.projectRepositories !== undefined ? Object.keys(ceeProject.projectRepositories)[0] : undefined;
				return newMapping;
			}).forEach(newMapping => {
				addRepositoryMapping.mutate(newMapping);
			});

			onClose();
		} finally {
			setLocked(false);
		}
	}

	function handleClose(): void {
		if (locked) {
			return;
		}

		onClose();
	}

	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<DialogTitle>Add Repository Mapping</DialogTitle>
			<DialogContent>
				<Grid container direction="column" paddingY="0.5em">
					<Grid item>
						<Autocomplete
							multiple
							options={repositories ?? []}
							getOptionLabel={(option) => option?.name ?? ""}
							isOptionEqualToValue={(option, value) => option?.id === value?.id}
							value={selectedRepositories}
							onChange={(_, value) => setSelectedRepositories(value)}
							renderInput={(params) => <TextField {...params} label="Repository" />}
							renderOption={(props, action, { selected }) => (
								<li {...props}>
									<Checkbox
										checked={selected}
									/>
									{action.name}
								</li>
							)}
							disabled={locked}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => handleClose()}
					disabled={locked}
				>
					Close
				</Button>
				<Button
					variant="contained"
					onClick={() => handleSave()}
					disabled={locked}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog >
	)
}

export default AddRepositoryMappingDialog;
