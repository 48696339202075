import { Box } from "@mui/material";
import { useMemo } from "react";
import ReactFlow, { Edge, MarkerType, Node, NodeTypes, Position, ReactFlowProvider } from "reactflow";
import { JobDto, JobGroupDto } from "../../model/cee/JobDto";
import JobNode from "./JobNode";

export function getJobDepth(job: JobDto | undefined, jobGroup: JobGroupDto, depth: number = 0): number {
	if (depth > 20) { return 20 } /* Prevent infinite loop by dependency cycles */

	return (job?.dependencies?.map(dep => {
		const depJob = getJobInJobGroup(jobGroup, dep.job?.uuid);
		return getJobDepth(depJob, jobGroup, depth + 1)
	}).reduce((a, b) => Math.max(a, b), depth) ?? 0);
}

function getJobInJobGroup(jobGroup: JobGroupDto, uuid?: string) {
	return jobGroup.jobs?.filter(j => j.uuid === uuid)[0];
}

function calculateNodeLayout(jobGroup: JobGroupDto | undefined) {
	let stepsAtDepth = [];

	let nodes: Node[] = [];

	for (let job of jobGroup?.jobs ?? []) {
		let depth = getJobDepth(job, jobGroup!);
		while (stepsAtDepth.length <= depth) { stepsAtDepth.push(0); }
		stepsAtDepth[depth]++;

		nodes.push({
			id: job.uuid,
			type: 'job',
			selectable: true,
			sourcePosition: Position.Right,
			targetPosition: Position.Left,
			position: { x: depth * 200, y: (stepsAtDepth[depth] - 1) * 50 },
			data: {
				job,
				jobGroup
			}
		});
	}

	return nodes;
}

const nodeTypes: NodeTypes = { 'job': JobNode };

interface JobGroupGraphProps {
	jobGroup?: JobGroupDto
	onJobSelected?: (job: JobDto) => void
}

const JobGroupGraph: React.FunctionComponent<JobGroupGraphProps> = props => {
	const { jobGroup, onJobSelected } = props;

	const nodes: Node[] = useMemo(() => calculateNodeLayout(jobGroup), [jobGroup]);

	const edges: Edge[] = useMemo(() => jobGroup?.jobs?.flatMap((job, i) => job.dependencies?.flatMap((dep, j) => {
		if (!dep.job) return [];
		return [{
			id: `${i}-${j}`,
			type: 'simplebezier',
			source: dep.job?.uuid,
			target: job.uuid,
			// style: { stroke: 'black' },
			markerEnd: {
				type: MarkerType.Arrow,
				width: 18,
				height: 18
			}
		}]
	}) ?? []) ?? [], [jobGroup?.jobs]);

	const height = nodes.map(n => n.position.y).reduce((a, b) => Math.max(a, b), 0) + 32;

	return <Box height={height} sx={{ '& .react-flow': { overflow: 'visible!important' }, '& .react-flow__pane': { cursor: 'default' } }}>
		<ReactFlowProvider>
			<ReactFlow
				nodes={nodes}
				edges={edges}
				nodeTypes={nodeTypes}
				nodesFocusable={false}
				nodesDraggable={false}
				panOnScroll={false}
				onMouseDownCapture={e => (e.button !== 0 && e.button !== 2) && e.stopPropagation()}
				nodesConnectable={false}
				elementsSelectable={false}
				panOnDrag={false}
				zoomOnScroll={false}
				zoomOnDoubleClick={false}
				zoomOnPinch={false}
				preventScrolling={false}
				proOptions={{ hideAttribution: true }}
				// onSelectionChange={e => onJobSelected?.(e.nodes[0]?.data?.job)}
				onNodeClick={(e, node) => onJobSelected?.(node.data?.job)}
			>
			</ReactFlow>
		</ReactFlowProvider>
	</Box>
};

export default JobGroupGraph;