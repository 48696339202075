import { useEffect, useState } from 'react';

export default function useMobile() {
	const [isMobile, setMobile] = useState(false);

	useEffect(() => {
		function handleWindowSizeChange() {
			setMobile(window.innerWidth <= 746);
		}

		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

	return isMobile;
}
