export default class ExternalRepositoryDto {
	id: number | undefined;
	name: string | undefined;
	type: ExternalRepositoryType | undefined;
	url: string | undefined;
	branch: string | undefined;
	authenticationType: ExternalRepositoryAuthenticationType | undefined;
	username: string | undefined;
	password: string | undefined;
	sshKey: string | undefined;
}

export enum ExternalRepositoryAuthenticationType {
	UsernamePassword = "USERNAME_PASSWORD",
	SshKey = "SSH_KEY"
}

export enum ExternalRepositoryType {
	Git = "GIT",
	Svn = "SVN"
}