import React from 'react';
import { Box, Typography } from '@mui/material';

const NoConfigFound: React.FunctionComponent = () => {
	return (
		<Box width='100%' height='100%' display='flex' justifyContent='center'>
			<Box display='flex' flexDirection='column' justifyContent='center'>
				<Typography variant='h6'>
					No config has been found. The frontend seems to be misconfigured. Please contact your system administrator.
				</Typography>
			</Box>
		</Box>
	)
}

export default NoConfigFound;